import React from 'react';
import Header from './../Components/Header';
import { withRouter } from 'react-router';

function SuccessPage({ history }) {
    const body = () => {
        return (
            <div className="o-layout o-layout--center">
                <div className="o-layout__item u-4/6 u-text-center">
                    <i className="c-icon c-icon--[semantic-checkmark]" />
                </div>
                <div className="o-layout__item u-4/6 u-text-center">
                    <p>
                        {' '}
                        Hemos recibido tus datos correctamente y procedemos a
                        hacer las comprobaciones pertinentes.{' '}
                    </p>
                    <p>
                        <strong>
                            {' '}
                            Pronto recibirás un correo para finalizar tu
                            registro.{' '}
                        </strong>
                    </p>
                </div>
            </div>
        );
    };

    const renderBody = () => {
        if (history.location.state && history.location.state.wrapper) {
            return (
                <div className="o-root">
                    <Header />
                    <main className="o-main">
                        <section className="o-content-section">
                            <div className="o-page-wrap o-page-wrap--xsmall">
                                <form
                                    id="main-form"
                                    className="js-floating-label u-p"
                                >
                                    <fieldset className="o-fieldset">
                                        <div className="o-fieldset__row">
                                            <div className="o-layout">
                                                <div className="o-layout__item">
                                                    {body()}
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                        </section>
                    </main>
                </div>
            );
        }
        return body();
    };

    return renderBody();
}
export default withRouter(SuccessPage);
