import { stringIsNullOrEmpty } from '../utils/validations';

export default {
    validations: {
        NIFNIE: 'NIF/NIE incorrecto',
        NOMBREEMPRESA: 'Nombre de Empresa incorrecto',
        TIPOVIA: 'Tipo de vía incorrecto',
        NOMBREVIA: 'Nombre de la vía incorrecto',
        NUMEROVIA: 'Número de la vía incorrecto',
        PISO: 'Piso incorrecto',
        CP: 'Código postal incorrecto',
        PROVINCIA: 'Provincia incorrecta',
        CORREO: 'Correo incorrecto',
        TELEFONO: 'Teléfono incorrecto',
        PAIS: 'País incorrecto',
        DIRECCION: 'Dirección incorrecta',
        POBLACION: 'Población incorrecta',
        NOMBRE: 'Nombre incorrecto o vacío',
        APELLIDO: 'Apellido incorrecto o vacío',
    },
};

export function validateInput(elementId, setError, error, customValidation, userType) {
    if (customValidation !== null && customValidation !== undefined) {
        if (customValidation(document.getElementById(elementId).value, userType)) {
            setError(null);
            return true;
        } else {
            setError(error);
            return false;
        }
    } else if (stringIsNullOrEmpty(document.getElementById(elementId).value)) {
        setError(error);
        return false;
    } else {
        setError(null);
        return true;
    }
}
