/* eslint-disable no-script-url */
import React, { useState, useEffect } from 'react';
import Header from '../Components/Header';
import CheckingDataForm from '../Components/CheckingDataForm';
import NewCompanyForm from '../Components/NewCompanyForm';
import NewUserForm from '../Components/NewUserForm';
import '../css/formPage.css';
import Spinner from '../Components/Spinner';
import SuccessPage from './SuccessPage';
import {
    GetUserCompanyInformation,
    LoginAWS,
} from '../services/registerService';

function FormPage() {
    const [showCheckingDataForm, setShowCheckingDataForm] = useState(true);
    const [showNewCompanyForm, setShowNewCompanyForm] = useState(false);
    const [showNewUserForm, setNewUserForm] = useState(false);

    const [userType, setUserType] = useState('A');
    const [isLoading, setIsLoading] = useState(false);

    const [potentialClientData, setPotentialClientData] = useState([]);
    const [nifCodigoEmpresa, setNifCodigoEmpresa] = useState(null);
    const [isUserBeingModified, setIsUserBeingModified] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const myParam = urlParams.get('hash');

        if (myParam && myParam !== '') {
            LoginAWS()
                .then(result => {
                    if (result && result.length > 0) {
                        setIsLoading(true);
                        GetUserCompanyInformation(myParam, result)
                            .then(result => {
                                if (result && result.length > 0) {
                                    setIsUserBeingModified(true);

                                    var data = JSON.parse(result);
                                    setPotentialClientData(JSON.parse(data.Result));
                                    setUserType(JSON.parse(data.Result).ClientType);
                                }

                                setNifCodigoEmpresa(JSON.parse(data.Result).CompanyNif);

                                if (JSON.parse(data.Result).PhysicalAddress.IdTemporalUser === JSON.parse(data.Result).TemporalUserId) {
                                    nextStepForm('checkingDataForm');
                                } else {
                                    nextStepForm('newUserForm');
                                }

                                setIsLoading(false);
                            })
                            .catch(error => {});
                    }
                })
                .catch(error => {});
        }
    }, [isUserBeingModified]);

    const nextStepForm = actualForm => {
        switch (actualForm) {
            case 'checkingDataForm':
                setShowCheckingDataForm(false);
                setShowNewCompanyForm(true);
                break;
            case 'newUserForm':
                setShowCheckingDataForm(false);
                setShowNewCompanyForm(false);
                setNewUserForm(true);
                break;
            default:
                break;
        }
    };

    const backStepForm = actualForm => {
        switch (actualForm) {
            case 'newUserForm':
                setShowCheckingDataForm(true);
                setShowNewCompanyForm(false);
                setNewUserForm(false);
                break;
            default:
                break;
        }
    };

    return (
        <React.Fragment>
            <div className="o-root">
                <Header />
                <main className="o-main">
                    <Spinner isLoading={isLoading} />
                    <section className="o-content-section">
                        <div className="o-page-wrap o-page-wrap--xsmall">
                            <form
                                id="main-form"
                                className="js-floating-label u-p"
                                action="javascript:void(0);"
                            >
                                <fieldset className="o-fieldset">
                                    <legend className="c-form-heading">
                                        <strong className="c-form-heading__title">
                                            <span className="c-form-heading__title-text">
                                                Rema 4.0
                                            </span>
                                        </strong>
                                    </legend>
                                    <div className="o-fieldset__row">
                                        {showCheckingDataForm ? (
                                            <CheckingDataForm
                                                nextStepForm={nextStepForm}
                                                userType={userType}
                                                setUserType={setUserType}
                                                setIsLoading={setIsLoading}
                                                potentialClientData={
                                                    potentialClientData
                                                }
                                                setPotentialClientData={
                                                    setPotentialClientData
                                                }
                                                nifCodigoEmpresa={nifCodigoEmpresa}
                                                setNifCodigoEmpresa={setNifCodigoEmpresa}
                                            />
                                        ) : null}
                                        {showNewCompanyForm ? (
                                            <NewCompanyForm
                                                userType={userType}
                                                setUserType={setUserType}
                                                setIsLoading={setIsLoading}
                                                potentialClientData={
                                                    potentialClientData
                                                }
                                                setPotentialClientData={
                                                    setPotentialClientData
                                                }
                                                isUserBeingModified={
                                                    isUserBeingModified
                                                }
                                            />
                                        ) : null}
                                        {showNewUserForm ? (
                                            !isRegistered ? (
                                                <NewUserForm
                                                    potentialClientData={
                                                        potentialClientData
                                                    }
                                                    setIsLoading={setIsLoading}
                                                    setPotentialClientData={
                                                        setPotentialClientData
                                                    }
                                                    setIsRegistered={
                                                        setIsRegistered
                                                    }
                                                    isUserBeingModified={
                                                        isUserBeingModified
                                                    }
                                                    nifCodigoEmpresa={nifCodigoEmpresa}
                                                />
                                            ) : (
                                                <SuccessPage />
                                            )
                                        ) : null}
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </section>
                </main>
            </div>
        </React.Fragment>
    );
}

export default FormPage;
