import React from 'react';
import mime from 'mime-types';

const DownloadButton = fileData => {
    const downloadFile = e => {
        e.preventDefault();
        const element = document.createElement('a');
        var file = null;
        if (fileData.newFileForm) {
            var decodedData = decodeURIComponent(
                atob(fileData.file.File)
                    .split('')
                    .map(function (c) {
                        return (
                            '%' +
                            ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                        );
                    })
                    .join('')
            );

            var data = fileData.file.FileName.split('.');
            file = new Blob([decodedData], { type: mime.lookup(data[1]) });
            element.download = fileData.file.FileName;
        } else {
            file = new Blob([fileData.file], { type: fileData.file.type });
            element.download = fileData.file.name;
        }
        element.href = URL.createObjectURL(file);
        document.body.appendChild(element);
        element.click();
    };

    return (
        <button
            className="c-upload-item__interaction-icon  c-upload-item__interaction-icon--main"
            onClick={e => downloadFile(e)}
        ></button>
    );
};

export default DownloadButton;
