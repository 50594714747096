import React, { useCallback, useState } from 'react';
import { withRouter } from 'react-router';
import {
    ValidateSpanishID,
    validateEmail,
    stringIsNullOrEmpty,
    ValidateSpanishIDNoCif
} from '../utils/validations';
import inputValidations, { validateInput } from '../utils/inputsValidations';
import UploadInline from './UploadInline';
import { InsertCompanyData } from '../services/registerService';

let validations = inputValidations.validations;

const NewUserForm = ({
    potentialClientData,
    setPotentialClientData,
    setIsLoading,
    history,
    isUserBeingModified,
    nifCodigoEmpresa,
}) => {
    const [nifNieUserError, setNifNieUserError] = useState(null);
    const [nombreUserError, setNombreUserError] = useState(null);
    const [pApellidoError, setPApellidoError] = useState(null);
    const [sApellidoError, setSApellidoError] = useState(null);
    const [correoUserError, setCorreoUserError] = useState(null);
    const [telefonoUserError, setTelefonoUserError] = useState(null);
    const [uploadDocumentsError, setUploadDocumentsError] = useState(null);
    const [procesoError, setProcesoError] = useState(null);

    const [userName, setUserName] = useState(null);
    const [userSurname1, setUserSurname1] = useState(null);
    const [userSurname2, setUserSurname2] = useState(null);
    const [phone1, setPhone1] = useState(null);

    const insertCompanyData = useCallback(() => {
        setIsLoading(true);

        InsertCompanyData(potentialClientData, isUserBeingModified).then(result => {
            setIsLoading(false);
            if (result) {
                if (result.Errors && result.Errors.length > 0) {
                    setProcesoError(result.Errors[0].Value);
                } else history.push('/success', { wrapper: true });
            }
        });
    }, [history, potentialClientData, setIsLoading, isUserBeingModified]);

    const mapObjectData = useCallback(() => {
        var inputs = document.forms['main-form'].getElementsByTagName('input');
        potentialClientData.userData = [];

        potentialClientData.CompanyNif = nifCodigoEmpresa;
        Array.from(inputs).forEach(element => {
            potentialClientData.userData[element.id] = element.value;
        });

        potentialClientData.stepRegisterUser = true;
    }, [potentialClientData, nifCodigoEmpresa]);

    const inputsNecessaryFilled = useCallback(() => {
        //Validación de que los inputs obligatorios están rellenados
        var validated = [];

        validated.push(
            validateInput(
                'dniUsuario',
                setNifNieUserError,
                validations.NIFNIE,
                ValidateSpanishIDNoCif
            )
        );
        validated.push(
            validateInput(
                'nombreUsuario',
                setNombreUserError,
                validations.NOMBRE
            )
        );
        validated.push(
            validateInput(
                'pApellidoUsuario',
                setPApellidoError,
                validations.APELLIDO
            )
        );
        validated.push(
            validateInput(
                'correo2Input',
                setCorreoUserError,
                validations.CORREO
            )
        );
        validated.push(
            validateInput(
                'telefonoUsuario',
                setTelefonoUserError,
                validations.TELEFONO
            )
        );

        if (
            potentialClientData == null ||
            potentialClientData === undefined ||
            potentialClientData.Documents === undefined ||
            potentialClientData.Documents.length <= 0
        ) {
            setUploadDocumentsError(
                'Debes adjuntar el documento para poder continuar'
            );
            return false;
        } else {
            setUploadDocumentsError(null);
        }

        if (validated.indexOf(false) === -1) {
            mapObjectData();
            insertCompanyData();
        }
    }, [insertCompanyData, mapObjectData, potentialClientData]);

    const onChangeOnlyLetters = useCallback(e => {
        const re = /^[A-Za-zñÑ.ºª\s]+$/;

        switch(e.target.id) {
            case "nombreUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = userName;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setUserName(e.target.value);
                }
                e.target.value !== ''
                ? setNombreUserError(
                      null
                  )
                : setNombreUserError(
                      validations.NOMBRE
                  )
                break;
            case "pApellidoUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = userSurname1;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setUserSurname1(e.target.value);
                }

                e.target.value !== ''
                ? setPApellidoError(
                      null
                  )
                : setPApellidoError(
                      validations.APELLIDO
                  )
                break;
            case "sApellidoUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = userSurname2;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setUserSurname2(e.target.value);
                }

                e.target.value !== ''
                ? setSApellidoError(
                      null
                  )
                : setSApellidoError(
                      validations.APELLIDO
                  )
                break;
            default:
            break;
        }
    }, [userName, userSurname1, userSurname2]);

    const onChangePhoneNumber = useCallback( e => {
        const re = /^[0-9\b]+$/;
        switch (e.target.id) {
            case "telefonoUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = phone1;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setPhone1(e.target.value);
                }

                e.target.value !== '' ? setTelefonoUserError(null)
                : setTelefonoUserError(validations.TELEFONO)
                break;
            default:
                break;
        }
    }, [phone1]);

    return (
        <React.Fragment>
            <p>
                <strong> Alta de usuario </strong>
            </p>
            <p> Por favor, introduce tus datos para completar el registro: </p>
            <div className="c-form-field u-mt-large">
                <div className="o-layout">
                    <div className="o-layout__item u-1/2">
                        <label
                            htmlFor="dniUsuario"
                            className="c-form-field__label"
                        >
                            <strong> NIF/NIE </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div className="c-input  ">
                                <input
                                    className="c-input__input"
                                    id="dniUsuario"
                                    type="text"
                                    placeholder=""
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    maxLength="9"
                                    onChange={e =>
                                        setNifNieUserError(
                                            ValidateSpanishIDNoCif(e.target.value)
                                                ? null
                                                : validations.NIFNIE
                                        )
                                    }
                                    defaultValue={
                                        !stringIsNullOrEmpty(
                                            potentialClientData.UserNif
                                        )
                                            ? potentialClientData.UserNif
                                            : ''
                                    }
                                />
                            </div>
                            <p
                                className="c-error-message"
                                id="correo1Input-error"
                            >
                                {' '}
                                {nifNieUserError}{' '}
                            </p>
                        </div>
                    </div>
                    <div className="o-layout__item u-1/2 u-1/1@xs">
                        <label
                            htmlFor="nombreUsuario"
                            className="c-form-field__label"
                        >
                            <strong> Nombre </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div className="c-input  ">
                                <input
                                    className="c-input__input"
                                    id="nombreUsuario"
                                    type="text"
                                    placeholder=""
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    onChange={e => onChangeOnlyLetters(e)}
                                    defaultValue={
                                        !stringIsNullOrEmpty(
                                            potentialClientData.UserName
                                        )
                                            ? potentialClientData.UserName
                                            : ''
                                    }
                                />
                            </div>
                            <p
                                className="c-error-message"
                                id="correo1Input-error"
                            >
                                {' '}
                                {nombreUserError}{' '}
                            </p>
                        </div>
                    </div>
                    <div className="o-layout__item u-1/2 u-1/1@xs">
                        <label
                            htmlFor="pApellidoUsuario"
                            className="c-form-field__label"
                        >
                            <strong> Primer Apellido </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div className="c-input  ">
                                <input
                                    className="c-input__input"
                                    id="pApellidoUsuario"
                                    type="text"
                                    placeholder=""
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    onChange={e => onChangeOnlyLetters(e)}
                                    defaultValue={
                                        !stringIsNullOrEmpty(
                                            potentialClientData.UserSurname
                                        )
                                            ? potentialClientData.UserSurname
                                            : ''
                                    }
                                />
                            </div>
                            <p
                                className="c-error-message"
                                id="correo1Input-error"
                            >
                                {' '}
                                {pApellidoError}{' '}
                            </p>
                        </div>
                    </div>
                    <div className="o-layout__item u-1/2 u-1/1@xs">
                        <label
                            htmlFor="sApellidoUsuario"
                            className="c-form-field__label"
                        >
                            <strong> Segundo Apellido </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div className="c-input  ">
                                <input
                                    className="c-input__input"
                                    id="sApellidoUsuario"
                                    type="text"
                                    placeholder=""
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    onChange={e => onChangeOnlyLetters(e)}
                                    defaultValue={
                                        !stringIsNullOrEmpty(
                                            potentialClientData.UserSurname2
                                        )
                                            ? potentialClientData.UserSurname2
                                            : ''
                                    }
                                />
                            </div>
                            <p
                                className="c-error-message"
                                id="correo1Input-error"
                            >
                                {' '}
                                {sApellidoError}{' '}
                            </p>
                        </div>
                    </div>
                    <div className="o-layout__item u-1/2 u-1/1@xs">
                        <label
                            htmlFor="correo2Input"
                            className="c-form-field__label"
                        >
                            <strong> Correo </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div className="c-input  ">
                                <input
                                    className="c-input__input"
                                    id="correo2Input"
                                    type="text"
                                    placeholder=""
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    onChange={e =>
                                        validateEmail(e.target.value) === true
                                            ? setCorreoUserError(null)
                                            : setCorreoUserError(
                                                  'Email incorrecto'
                                              )
                                    }
                                    defaultValue={
                                        !stringIsNullOrEmpty(
                                            potentialClientData.UserMail
                                        )
                                            ? potentialClientData.UserMail
                                            : ''
                                    }
                                />
                            </div>
                            <p
                                className="c-error-message"
                                id="correo2Input-error"
                            >
                                {' '}
                                {correoUserError}{' '}
                            </p>
                        </div>
                    </div>
                    <div className="o-layout__item u-1/2 u-1/1@xs">
                        <label
                            htmlFor="telefonoUsuario"
                            className="c-form-field__label"
                        >
                            <strong> Nº de teléfono </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div className="c-input  ">
                                <input
                                    className="c-input__input"
                                    id="telefonoUsuario"
                                    type="text"
                                    placeholder=""
                                    maxLength={14}
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    onChange={e => onChangePhoneNumber(e)}
                                    defaultValue={
                                        !stringIsNullOrEmpty(
                                            potentialClientData.UserPhone
                                        )
                                            ? potentialClientData.UserPhone
                                            : ''
                                    }
                                />
                            </div>
                            <p
                                className="c-error-message"
                                id="telefonoUserInput-error"
                            >
                                {' '}
                                {telefonoUserError}{' '}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="c-form-field">
                <p> Debes adjuntar los siguientes documentos: </p>
                <ul className="c-ul u-mb-large o-list-bare">
                    <li className="u-mb-small">
                        <UploadInline
                            nombreFicheroASubir="NIF/NIE"
                            tipoDoc={
                                process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_ID
                            }
                            potentialClientData={potentialClientData}
                            setPotentialClientData={setPotentialClientData}
                        />
                    </li>
                </ul>
                <p className="u-mb">
                    {' '}
                    Formatos aceptados: .jpg, .png, .doc, .pdf{' '}
                </p>
            </div>
            <p className="c-error-message u-mt" id="upload-documents-error">
                {' '}
                {uploadDocumentsError}{' '}
            </p>
            <p className="c-error-message" id="proceso-error">
                {' '}
                {procesoError}{' '}
            </p>
            <div className="o-layout o-layout--right">
                <div className="o-layout__item u-3/12 u-4/12@s u-12/12@xs">
                    <div className="c-form-field">
                        <button
                            className="c-btn"
                            onClick={() => inputsNecessaryFilled()}
                        >
                            <span className="c-btn__text">Siguiente</span>
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default withRouter(NewUserForm);
