import React, { useCallback, useState, useEffect } from 'react';
import {
    GetCountries,
    GetProvinces,
    GetVillages,
} from '../services/listsService';
import inputValidations from '../utils/inputsValidations';
import { stringIsNullOrEmpty } from '../utils/validations';

let validations = inputValidations.validations;

const AddressInputs = ({
    addressType,
    userType,
    tipoViaError,
    setTipoViaError,
    nombreViaError,
    setNombreViaError,
    cpError,
    setCpError,
    provinciaError,
    setProvinciaError,
    poblacionError,
    setPoblacionError,
    paisError,
    setPaisError,
    direccionError,
    setDireccionError,
    dataAddress,
    roadsList,
}) => {
    const [villageDisabled, setVillageDisabled] = useState(false);

    const [postalCode, setPostalCode] = useState('');
    const [provincesList, setProvincesList] = useState([]);
    const [villagesList, setVillagesList] = useState([]);
    const [countriesList, setCountriesList] = useState([]);

    useEffect(() => {
        if (
            !stringIsNullOrEmpty(dataAddress) &&
            !stringIsNullOrEmpty(dataAddress.PostalCode)
        ) {
            setPostalCode(dataAddress.PostalCode);
        }
    }, [dataAddress]);

    useEffect(() => {
        if (userType === 'E') {
            GetCountries()
                .then(result => {
                    if (result) {
                        setCountriesList(JSON.parse(result));
                    }
                })
                .catch(error => {});
            GetVillages(postalCode)
                .then(result => {
                    if (result) {
                        let villages = JSON.parse(result);
                        setVillagesList(villages);
                        setVillageDisabled(villages.length === 1);
                    }
                })
                .catch(error => {});
        }
    }, [userType, setCountriesList, postalCode]);

    useEffect(() => {
        if (postalCode.length > 4) {
            GetProvinces(postalCode)
                .then(result => {
                    if (result) {
                        setProvincesList(JSON.parse(result));
                    }
                })
                .catch(error => {});
            GetVillages(postalCode)
                .then(result => {
                    if (result) {
                        let villages = JSON.parse(result);
                        setVillagesList(villages);
                        setVillageDisabled(villages.length === 1);
                    }
                })
                .catch(error => {});
        }
    }, [postalCode, setVillagesList, setProvincesList]);

    const postalCodeOnChange = useCallback(
        e => {
            if (userType === 'A') {
                const re = /^[0-9\b]+$/;

                if (e.target.value === '' || re.test(e.target.value)) {
                    setPostalCode(e.currentTarget.value);
    
                    if (e && e.currentTarget.value.length > 4) {
                        setProvinciaError(null);
                        setPoblacionError(null);
                        setCpError(null);
                    } else {
                        setProvincesList([]);
                        setVillagesList([]);
                        setCpError(validations.CP);
                    }
                }
            } else {
                const re = /^\S*$/;

                if (e.target.value !== '' && re.test(e.target.value)) {
                    setPostalCode(e.currentTarget.value);
                    setCpError(null);
                } else {
                    setCpError(validations.CP);
                }
            }
        },
        [setPoblacionError, setProvinciaError, setCpError, userType]
    );

    const addressUserType = useCallback(() => {
        switch (userType) {
            case 'A':
                return (
                    <React.Fragment>
                        <div className="c-form-field u-mb-large">
                            <div className="o-layout">
                                <div className="o-layout__item u-4/12 u-12/12@xs">
                                    <label
                                        htmlFor={'tipoViaA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Tipo de vía </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        {
                                            <React.Fragment>
                                                <div className="c-input  c-input--select">
                                                    <select
                                                        id={
                                                            'tipoViaA' +
                                                            addressType
                                                        }
                                                        className="c-input__input"
                                                        onChange={e =>
                                                            e.target.value !==
                                                            ''
                                                                ? setTipoViaError(
                                                                      null
                                                                  )
                                                                : setTipoViaError(
                                                                      validations.TIPOVIA
                                                                  )
                                                        }
                                                    >
                                                        {roadsList.length > 0
                                                            ? Object.keys(
                                                                  roadsList
                                                              ).map(function (
                                                                  key,
                                                                  index
                                                              ) {
                                                                  return (
                                                                      <option
                                                                          key={
                                                                              key
                                                                          }
                                                                          value={
                                                                              roadsList[
                                                                                  index
                                                                              ]
                                                                                  .TPCCODTPVIA
                                                                          }
                                                                      >
                                                                          {
                                                                              roadsList[
                                                                                  index
                                                                              ]
                                                                                  .TPCDESCTPVIA
                                                                          }
                                                                      </option>
                                                                  );
                                                              })
                                                            : null}
                                                    </select>
                                                </div>
                                                <p className="c-error-message">
                                                    {' '}
                                                    {tipoViaError}{' '}
                                                </p>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="o-layout__item u-8/12 u-12/12@xs">
                                    <label
                                        htmlFor={'nombreViaA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Nombre de la vía </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'nombreViaA' + addressType}
                                                type="text"
                                                placeholder=""
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                onChange={e =>
                                                    e.target.value !== ''
                                                        ? setNombreViaError(
                                                              null
                                                          )
                                                        : setNombreViaError(
                                                              validations.NOMBREVIA
                                                          )
                                                }
                                                defaultValue={
                                                    !stringIsNullOrEmpty(
                                                        dataAddress
                                                    ) &&
                                                    !stringIsNullOrEmpty(
                                                        dataAddress.StreetName
                                                    )
                                                        ? dataAddress.StreetName
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <p className="c-error-message">
                                            {' '}
                                            {nombreViaError}{' '}
                                        </p>
                                    </div>
                                </div>
                                <div className="o-layout__item u-4/12 u-12/12@xs">
                                    <label
                                        htmlFor={'numeroA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Número (opcional) </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'numeroA' + addressType}
                                                type="number"
                                                placeholder=""
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                defaultValue={
                                                    !stringIsNullOrEmpty(
                                                        dataAddress
                                                    ) &&
                                                    !stringIsNullOrEmpty(
                                                        dataAddress.Number
                                                    )
                                                        ? dataAddress.Number
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="o-layout__item u-4/12 u-12/12@xs">
                                    <label
                                        htmlFor={'pisoA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Piso (opcional)</strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'pisoA' + addressType}
                                                type="number"
                                                placeholder=""
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                defaultValue={
                                                    !stringIsNullOrEmpty(
                                                        dataAddress
                                                    ) &&
                                                    !stringIsNullOrEmpty(
                                                        dataAddress.Floor
                                                    )
                                                        ? dataAddress.Floor
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="o-layout__item u-4/12 u-12/12@xs">
                                    <label
                                        htmlFor={'puertaA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Puerta (opcional) </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'puertaA' + addressType}
                                                type="text"
                                                placeholder=""
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                defaultValue={
                                                    !stringIsNullOrEmpty(
                                                        dataAddress
                                                    ) &&
                                                    !stringIsNullOrEmpty(
                                                        dataAddress.Door
                                                    )
                                                        ? dataAddress.Door
                                                        : ''
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="o-layout__item u-2/12 u-12/12@xs">
                                    <label
                                        htmlFor={'cpA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> CP </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'cpA' + addressType}
                                                type="text"
                                                placeholder=""
                                                maxLength="5"
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                onChange={postalCodeOnChange}
                                                value={postalCode}
                                            />
                                        </div>
                                        <p className="c-error-message">
                                            {' '}
                                            {cpError}{' '}
                                        </p>
                                    </div>
                                </div>
                                <div className="o-layout__item u-5/12 u-12/12@xs">
                                    <label
                                        htmlFor={'provinciaA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Provincia </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        {
                                            <React.Fragment>
                                                <div className="c-input  c-input--select">
                                                    <select 
                                                        id={
                                                            'provinciaA' +
                                                            addressType
                                                        }
                                                        className="c-input__input"
                                                        readOnly={true}
                                                        value={
                                                            dataAddress?.Province
                                                        }
                                                    >
                                                        {provincesList.length >
                                                        0
                                                            ? Object.keys(
                                                                  provincesList
                                                              ).map(function (
                                                                  key,
                                                                  index
                                                              ) {
                                                                  return (
                                                                      <option
                                                                          key={
                                                                              key
                                                                          }
                                                                          value={
                                                                              provincesList[
                                                                                  index
                                                                              ]
                                                                                  .CODIGO
                                                                          }
                                                                      >
                                                                          {
                                                                              provincesList[
                                                                                  index
                                                                              ]
                                                                                  .DESCRIPCION
                                                                          }
                                                                      </option>
                                                                  );
                                                              })
                                                            : null}
                                                    </select>
                                                </div>
                                                <p className="c-error-message">
                                                    {' '}
                                                    {provinciaError}{' '}
                                                </p>
                                            </React.Fragment>
                                        }
                                    </div>
                                </div>
                                <div className="o-layout__item u-5/12 u-12/12@xs">
                                    <label
                                        htmlFor={'poblacionA-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Población </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        {
                                            <div className="c-form-field__box">
                                                <div className="c-input  c-input--select">
                                                    <select
                                                        id={
                                                            'poblacionA' +
                                                            addressType
                                                        }
                                                        className="c-input__input"
                                                        disabled={
                                                            villageDisabled
                                                        }
                                                        value={
                                                            dataAddress?.Town
                                                        }
                                                    >
                                                        {villagesList.length > 0
                                                            ? Object.keys(
                                                                  villagesList
                                                              ).map(function (
                                                                  key,
                                                                  index
                                                              ) {
                                                                  return (
                                                                      <option
                                                                          key={
                                                                              key
                                                                          }
                                                                          value={
                                                                              villagesList[
                                                                                  index
                                                                              ]
                                                                                  .UBNCODIGOPOBLACION
                                                                          }
                                                                      >
                                                                          {
                                                                              villagesList[
                                                                                  index
                                                                              ]
                                                                                  .UBCNOMBRE
                                                                          }
                                                                      </option>
                                                                  );
                                                              })
                                                            : null}
                                                    </select>
                                                </div>
                                                <p className="c-error-message">
                                                    {' '}
                                                    {poblacionError}{' '}
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            case 'E':
                return (
                    <React.Fragment>
                        <div className="c-form-field">
                            <div className="o-layout">
                                <div className="o-layout__item u-12/12">
                                    <label
                                        htmlFor={'paisE-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> País </strong>
                                    </label>
                                    {
                                        <div className="c-form-field__box">
                                            <div className="c-input  c-input--select">
                                                <select
                                                    id={'paisE' + addressType}
                                                    className="c-input__input"
                                                    onChange={e =>
                                                        e.target.value !== ''
                                                            ? setPaisError(null)
                                                            : setPaisError(
                                                                  validations.PAIS
                                                              )
                                                    }
                                                    value={dataAddress?.Country}
                                                >
                                                    {countriesList.length > 0
                                                        ? Object.keys(
                                                              countriesList
                                                          ).map(function (
                                                              key,
                                                              index
                                                          ) {
                                                              return (
                                                                  <option
                                                                      key={key}
                                                                      value={
                                                                          countriesList[
                                                                              index
                                                                          ]
                                                                              .UBCCODPAIS
                                                                      }
                                                                  >
                                                                      {
                                                                          countriesList[
                                                                              index
                                                                          ]
                                                                              .UBCDESCPAIS
                                                                      }
                                                                  </option>
                                                              );
                                                          })
                                                        : null}
                                                </select>
                                            </div>
                                            <p className="c-error-message">
                                                {' '}
                                                {paisError}{' '}
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="c-form-field u-mt">
                            <div className="o-layout">
                                <div className="o-layout__item u-12/12">
                                    <label
                                        htmlFor={'nombreViaE-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Dirección </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'nombreViaE' + addressType}
                                                type="text"
                                                placeholder=""
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                onChange={e =>
                                                    e.target.value !== ''
                                                        ? setDireccionError(
                                                              null
                                                          )
                                                        : setDireccionError(
                                                              validations.DIRECCION
                                                          )
                                                }
                                                defaultValue={
                                                    !stringIsNullOrEmpty(
                                                        dataAddress
                                                    ) &&
                                                    !stringIsNullOrEmpty(
                                                        dataAddress.StreetName
                                                    )
                                                        ? dataAddress.StreetName
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <p className="c-error-message">
                                            {' '}
                                            {direccionError}{' '}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="c-form-field u-mt">
                            <div className="o-layout">
                                <div className="o-layout__item u-6/12 u-12/12@xs">
                                    <label
                                        htmlFor={'cpE-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> CP/ZIP Code </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'cpE' + addressType}
                                                type="text"
                                                placeholder=""
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                onChange={postalCodeOnChange}
                                                maxLength="20"
                                                defaultValue={
                                                    !stringIsNullOrEmpty(
                                                        dataAddress
                                                    ) &&
                                                    !stringIsNullOrEmpty(
                                                        dataAddress.PostalCodeExporter
                                                    )
                                                        ? dataAddress.PostalCodeExporter
                                                        : ''
                                                }
                                            />
                                        </div>
                                        <p className="c-error-message">
                                            {' '}
                                            {cpError}{' '}
                                        </p>
                                    </div>
                                </div>
                                <div className="o-layout__item u-6/12 u-12/12@xs">
                                    <label
                                        htmlFor={'poblacionE-' + addressType}
                                        className="c-form-field__label"
                                    >
                                        <strong> Población </strong>
                                    </label>
                                    <div className="c-form-field__box">
                                        <div className="c-input  ">
                                            <input
                                                className="c-input__input"
                                                id={'poblacionE' + addressType}
                                                type="text"
                                                placeholder=""
                                                data-tr-component="Input Field"
                                                data-tr-function="Text Input"
                                                defaultValue={
                                                    userType === "A" ? dataAddress?.Town : dataAddress?.TownExporter
                                                }
                                            />
                                        </div>
                                        <p className="c-error-message">
                                            {' '}
                                            {poblacionError}{' '}
                                        </p>
                                    </div>
                                    {/* <div className="c-form-field__box">
                                        {
                                            <div className="c-form-field__box">
                                                <div className="c-input  c-input--select">
                                                    <select
                                                        id={
                                                            'poblacionE' +
                                                            addressType
                                                        }
                                                        className="c-input__input"
                                                        disabled={
                                                            villageDisabled
                                                        }
                                                        value={
                                                            dataAddress?.Town
                                                        }
                                                    >
                                                        {villagesList.length > 0
                                                            ? Object.keys(
                                                                  villagesList
                                                              ).map(function (
                                                                  key,
                                                                  index
                                                              ) {
                                                                  return (
                                                                      <option
                                                                          key={
                                                                              key
                                                                          }
                                                                          value={
                                                                              villagesList[
                                                                                  index
                                                                              ]
                                                                                  .UBNCODIGOPOBLACION
                                                                          }
                                                                      >
                                                                          {
                                                                              villagesList[
                                                                                  index
                                                                              ]
                                                                                  .UBCNOMBRE
                                                                          }
                                                                      </option>
                                                                  );
                                                              })
                                                            : null}
                                                    </select>
                                                </div>
                                                <p className="c-error-message">
                                                    {' '}
                                                    {poblacionError}{' '}
                                                </p>
                                            </div>
                                        }
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                );
            default:
                break;
        }
    }, [
        addressType,
        countriesList,
        cpError,
        dataAddress,
        direccionError,
        nombreViaError,
        paisError,
        poblacionError,
        postalCode,
        postalCodeOnChange,
        provincesList,
        provinciaError,
        roadsList,
        setDireccionError,
        setNombreViaError,
        setPaisError,
        setTipoViaError,
        tipoViaError,
        userType,
        villageDisabled,
        villagesList,
    ]);

    return addressUserType();
};

export default AddressInputs;
