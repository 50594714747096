import React from 'react';

const StepperNav = ({ step }) => {
    return (
        <nav className="c-stepper-nav  ">
            <ol className="c-stepper-nav__list">
                <li
                    className={
                        'c-stepper-nav__item ' +
                        (step === 1 ? 'is-current' : '') +
                        (step > 1 ? 'is-active' : '')
                    }
                >
                    <a className="c-stepper-nav__link" href="#0">
                        <div className="c-stepper-nav__box">
                            <span className="c-stepper-nav__title">
                                {' '}
                                Datos de Empresa{' '}
                            </span>
                        </div>
                    </a>
                </li>
                <li
                    className={
                        'c-stepper-nav__item ' +
                        (step === 2 ? 'is-current' : '') +
                        (step > 2 ? 'is-active' : '')
                    }
                >
                    <a className="c-stepper-nav__link" href="#0">
                        <div className="c-stepper-nav__box">
                            <span className="c-stepper-nav__title">
                                {' '}
                                Datos de Usuario{' '}
                            </span>
                        </div>
                    </a>
                </li>
                <li
                    className={
                        'c-stepper-nav__item ' +
                        (step === 3 ? 'is-current' : '') +
                        (step > 3 ? 'is-active' : '')
                    }
                >
                    <a className="c-stepper-nav__link" href="#0">
                        <div className="c-stepper-nav__box">
                            <span className="c-stepper-nav__title">
                                {' '}
                                Titulares Reales{' '}
                            </span>
                        </div>
                    </a>
                </li>
                <li
                    className={
                        'c-stepper-nav__item ' +
                        (step === 4 ? 'is-current' : '') +
                        (step > 4 ? 'is-active' : '')
                    }
                >
                    <a className="c-stepper-nav__link" href="#0">
                        <div className="c-stepper-nav__box">
                            <span className="c-stepper-nav__title">
                                {' '}
                                Archivos adjuntos{' '}
                            </span>
                        </div>
                    </a>
                </li>
            </ol>
        </nav>
    );
};

export default StepperNav;
