export default function baseCoreServer() {
    var hostname = window.location.hostname;

    if (hostname) {
        if (hostname.indexOf("register.dev") !== -1) {
            return process.env.REACT_APP_URL_BASE_CORE_SERVER;
        } else if (hostname.indexOf("localhost") !== -1) {
            return process.env.REACT_APP_URL_BASE_CORE_LOCAL_SERVER;
        } else {
            return process.env.REACT_APP_URL_BASE_CORE_LOCAL_SERVER;
        }
    }
}