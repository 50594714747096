import React from 'react';
const selectCountries  = 
    [
        {
            label:"ESP",
            value:"España"
        },
        {
            label:"AD",
            value:"Andorra"
        },
        {                    
            label:"AE",
            value:"Emiratos Árabes Unidos"
        },
        {                    
            label:"AF",
            value:"Afganistán"
        },
        {                    
            label:"AG",
            value:"Antigua y Barbuda"
        },
        {                    
            label:"AI",
            value:"Anguila"
        },
        {                    
            label:"AL",
            value:"Albania"
        },
        {                    
            label:"AM",
            value:"Armenia"
        },
        {                    
            label:"AN",
            value:"Curaçao (Antillas Neerlandesas)"
        },
        {                    
            label:"AO",
            value:"Angola"
        },
        {                    
            label:"AQ",
            value:"Antarctica"
        },
        {                    
            label:"AR",
            value:"Argentina"
        },
        {
            label:"AS",
            value:"American Samoa"
        },
        {
            label:"AT",
            value:"Austria"
        },
        {
            label:"AU",
            value:"Australia"
        },
        {
            label:"AW",
            value:"Aruba"
        },
		{
            label:"AX",
            value:"Aaland Islands"
        },
		{
            label:"AZ",
            value:"Azerbaiyán"
        },
		{
            label:"BA",
            value:"Bosnia y Hercegovina"
        },
		{
            label:"BB",
            value:"Barbados"
        },
		{
            label:"BD",
            value:"Bangladesh"
        },
		{
            label:"BE",
            value:"Bélgica"
        },
		{
            label:"BF",
            value:"Burkina Faso"
        },
		{
            label:"BG",
            value:"Bulgaria"
        },
		{
            label:"BH",
            value:"Bahráin"
        },
		{
            label:"BI",
            value:"Burundi"
        },
		{
            label:"BJ",
            value:"Benín"
        },
		{
            label:"BM",
            value:"Bermudas"
        },
		{
            label:"BN",
            value:"Brunéi"
        },
		{
            label:"BO",
            value:"Bolivia"
        },
		{
            label:"BR",
            value:"Brasil"
        },
		{
            label:"BS",
            value:"Bahamas"
        },
		{
            label:"BT",
            value:"Bután"
        },
		{
            label:"BV",
            value:"Bouvet Islands"
        },
		{
            label:"BW",
            value:"Botsuana"
        },
		{
            label:"BY",
            value:"Bielorrusia"
        },
		{
            label:"BZ",
            value:"Belice"
        },
		{
            label:"CA",
            value:"Canadá"
        },
		{
            label:"CC",
            value:"Cocos Islands"
        },
		{
            label:"CD",
            value:"Rep. Dem. Congo"
        },
		{
            label:"CF",
            value:"República Centroafricana"
        },
		{
            label:"CG",
            value:"Congo"
        },
		{
            label:"CH",
            value:"Suiza"
        },
		{
            label:"CI",
            value:"Costa de Marfil"
        },
		{
            label:"CK",
            value:"Cook Islands"
        },
		{
            label:"CL",
            value:"Chile"
        },
		{
            label:"CM",
            value:"Camerún"                    
		},
		{
            label:"CN",
            value:"China" 
			   },
		{

            label:"CO",
            value:"Colombia" 
                   },
            {

            label:"CR",
            value:"Costa Rica" 
                   },
            {

            label:"CS",
            value:"Serbia y Montenegro" 
                   },
            {

            label:"CU",
            value:"Cuba" 
                   },
            {

            label:"CV",
            value:"Cabo Verde" 
                   },
            {

            label:"CX",
            value:"Christmas Islands" 
                   },
            {

            label:"CY",
            value:"Chipre" 
                   },
            {

            label:"CZ",
            value:"Chequia" 
                   },
            {

            label:"DE",
            value:"Alemania" 
                   },
            {

            label:"DJ",
            value:"Djibouti" 
                   },
            {

            label:"DK",
            value:"Dinamarca" 
                   },
            {

            label:"DM",
            value:"Dominica" 
                   },
            {

            label:"DO",
            value:"República Dominicana" 
                   },
            {

            label:"DZ",
            value:"Argelia" 
                   },
            {

            label:"EC",
            value:"Ecuador" 
                   },
            {

            label:"EE",
            value:"Estonia" 
                   },
            {

            label:"EG",
            value:"Egipto" 
                   },
            {

            label:"EH",
            value:"Sáhara Occidental" 
                   },
            {

            label:"EL",
            value:"Grecia" 
                   },
            {

            label:"ER",
            value:"Eritrea" 
                   },
            {

            label:"ET",
            value:"Etiopía" 
                   },
            {

            label:"FI",
            value:"Finlandia" 
                   },
            {

            label:"FJ",
            value:"Fiyi" 
                   },
            {

            label:"FK",
            value:"Falkland Islands" 
                   },
            {

            label:"FM",
            value:"Micronesia" 
                   },
            {

            label:"FO",
            value:"Faroe Islands" 
                   },
            {

            label:"FR",
            value:"Francia" 
                   },
            {

            label:"GA",
            value:"Gabón" 
                   },
            {

            label:"GD",
            value:"Granada" 
                   },
            {

            label:"GE",
            value:"Georgia" 
                   },
            {

            label:"GF",
            value:"Guayana Francesa" 
                   },
            {

            label:"GH",
            value:"Ghana" 
                   },
            {

            label:"GI",
            value:"Gibraltar" 
                   },
            {

            label:"GL",
            value:"Groenlandia" 
                   },
            {

            label:"GM",
            value:"Gambia" 
                   },
            {

            label:"GN",
            value:"Guinea" 
                   },
            {

            label:"GP",
            value:"Guadalupe" 
                   },
            {

            label:"GQ",
            value:"Guinea Ecuatorial" 
                   },
            {

            label:"GS",
            value:"South Georgia Islands" 
                   },
            {

            label:"GT",
            value:"Guatemala" 
                   },
            {

            label:"GU",
            value:"Guam" 
                   },
            {

            label:"GW",
            value:"Guinea-Bissau" 
                   },
            {

            label:"GY",
            value:"Guyana República" 
                   },
            {

            label:"HK",
            value:"Hong Kong" 
                   },
            {

            label:"HM",
            value:"Mc Donald Islands" 
                   },
            {

            label:"HN",
            value:"Honduras" 
                   },
            {

            label:"HR",
            value:"Croacia" 
                   },
            {

            label:"HT",
            value:"Haití" 
                   },
            {

            label:"HU",
            value:"Hungría" 
                   },
            {

            label:"ID",
            value:"Indonesia" 
                   },
            {

            label:"IE",
            value:"Irlanda" 
                   },
            {

            label:"IL",
            value:"Israel" 
                   },
            {

            label:"IN",
            value:"India" 
                   },
            {

            label:"IO",
            value:"British Indian Ocean Territ." 
                   },
            {

            label:"IQ",
            value:"Iraq" 
                   },
            {

            label:"IR",
            value:"Irán" 
                   },
            {

            label:"IS",
            value:"Islandia" 
                   },
            {

            label:"IT",
            value:"Italia" 
                   },
            {

            label:"JM",
            value:"Jamaica" 
                   },
            {

            label:"JO",
            value:"Jordania" 
                   },
            {

            label:"JP",
            value:"Japón" 
                   },
            {

            label:"KE",
            value:"Kenia" 
                   },
            {

            label:"KG",
            value:"Kirguizistán" 
                   },
            {

            label:"KH",
            value:"Camboya" 
                   },
            {

            label:"KI",
            value:"Kiribati" 
                   },
            {

            label:"KM",
            value:"Comoras" 
                   },
            {

            label:"KN",
            value:"Saint Kitts and Nevis" 
                   },
            {

            label:"KP",
            value:"Corea del Norte" 
                   },
            {

            label:"KR",
            value:"Corea del Sur" 
                   },
            {

            label:"KW",
            value:"Kuwait" 
                   },
            {

            label:"KY",
            value:"Cayman Islands" 
                   },
            {

            label:"KZ",
            value:"Kazajistán" 
                   },
            {

            label:"LA",
            value:"Laos" 
                   },
            {

            label:"LB",
            value:"Líbano" 
                   },
            {

            label:"LC",
            value:"Santa Lucía" 
                   },
            {

            label:"LI",
            value:"Liechtenstein" 
                   },
            {

            label:"LK",
            value:"Sri Lanka" 
                   },
            {

            label:"LR",
            value:"Liberia" 
                   },
            {

            label:"LS",
            value:"Lesoto" 
                   },
            {

            label:"LT",
            value:"Lituania" 
                   },
            {

            label:"LU",
            value:"Luxemburgo" 
                   },
            {

            label:"LV",
            value:"Letonia" 
                   },
            {

            label:"LY",
            value:"Libia" 
                   },
            {

            label:"MA",
            value:"Marruecos" 
                   },
            {

            label:"MC",
            value:"Mónaco" 
                   },
            {

            label:"MD",
            value:"Moldavia" 
                   },
            {

            label:"ME",
            value:"Montenegro" 
                   },
            {

            label:"MG",
            value:"Madagascar" 
                   },
            {

            label:"MH",
            value:"Marshall Islands" 
                   },
            {

            label:"MK",
            value:"Macedonia" 
                   },
            {

            label:"ML",
            value:"Malí" 
                   },
            {

            label:"MM",
            value:"Myanmar" 
                   },
            {

            label:"MN",
            value:"Mongolia" 
                   },
            {

            label:"MO",
            value:"Macao" 
                   },
            {

            label:"MP",
            value:"Northern Mariana Islands" 
                   },
            {

            label:"MQ",
            value:"Martinica" 
                   },
            {

            label:"MR",
            value:"Mauritania" 
                   },
            {

            label:"MS",
            value:"Montserrat" 
                   },
            {

            label:"MT",
            value:"Malta" 
                   },
            {

            label:"MU",
            value:"Mauricio" 
                   },
            {

            label:"MV",
            value:"Maldivas" 
                   },
            {

            label:"MW",
            value:"Malaui" 
                   },
            {

            label:"MX",
            value:"México" 
                   },
            {

            label:"MY",
            value:"Malasia" 
                   },
            {

            label:"MZ",
            value:"Mozambique" 
                   },
            {

            label:"NA",
            value:"Namibia" 
                   },
            {

            label:"NC",
            value:"Nueva Caledonia" 
                   },
            {

            label:"NE",
            value:"Níger" 
                   },
            {

            label:"NF",
            value:"Norfolk Islands" 
                   },
            {

            label:"NG",
            value:"Nigeria" 
                   },
            {

            label:"NI",
            value:"Nicaragua" 
                   },
            {

            label:"NL",
            value:"Países Bajos" 
                   },
            {

            label:"NO",
            value:"Noruega" 
                   },
            {

            label:"NP",
            value:"Nepal" 
                   },
            {

            label:"NR",
            value:"Nauru" 
                   },
            {

            label:"NU",
            value:"Niue" 
                   },
            {

            label:"NZ",
            value:"Nueva Zelanda" 
                   },
            {

            label:"OM",
            value:"Omán" 
                   },
            {

            label:"PA",
            value:"Panamá" 
                   },
            {

            label:"PE",
            value:"Perú" 
                   },
            {

            label:"PF",
            value:"Polinesia Francesa" 
                   },
            {

            label:"PG",
            value:"Papúa-Nueva Guinea" 
                   },
            {

            label:"PH",
            value:"Filipinas" 
                   },
            {

            label:"PK",
            value:"Pakistán" 
                   },
            {

            label:"PL",
            value:"Polonia" 
                   },
            {

            label:"PM",
            value:"Saint Pierre and Miquelon" 
                   },
            {

            label:"PN",
            value:"Pitcairn" 
                   },
            {

            label:"PR",
            value:"Puerto Rico" 
                   },
            {

            label:"PS",
            value:"Palestina" 
                   },
            {

            label:"PT",
            value:"Portugal" 
                   },
            {

            label:"PW",
            value:"Palaos" 
                   },
            {

            label:"PY",
            value:"Paraguay" 
                   },
            {

            label:"QA",
            value:"Qatar" 
                   },
            {

            label:"RE",
            value:"Reunión" 
                   },
            {

            label:"RO",
            value:"Rumania" 
                   },
            {

            label:"RS",
            value:"Serbia" 
                   },
            {

            label:"RU",
            value:"Rusia" 
                   },
            {

            label:"RW",
            value:"Ruanda" 
                   },
            {

            label:"SA",
            value:"Arabia Saudí" 
                   },
            {

            label:"SB",
            value:"Solomon Islands" 
                   },
            {

            label:"SC",
            value:"Seychelles" 
                   },
            {

            label:"SD",
            value:"Sudán" 
                   },
            {

            label:"SE",
            value:"Suecia" 
                   },
            {

            label:"SG",
            value:"Singapur" 
                   },
            {

            label:"SH",
            value:"Saint Helena" 
                   },
            {

            label:"SI",
            value:"Eslovenia" 
                   },
            {

            label:"SJ",
            value:"Svalbarf and Jan Mayen" 
                   },
            {

            label:"SK",
            value:"Eslovaquia" 
                   },
            {

            label:"SL",
            value:"Sierra Leona" 
                   },
            {

            label:"SM",
            value:"San Marino" 
                   },
            {

            label:"SN",
            value:"Senegal" 
                   },
            {

            label:"SO",
            value:"Somalia" 
                   },
            {

            label:"SR",
            value:"Surinam" 
                   },
            {

            label:"ST",
            value:"Sao Tome and Principe" 
                   },
            {

            label:"SV",
            value:"El Salvador" 
                   },
            {

            label:"SY",
            value:"Siria" 
                   },
            {

            label:"SZ",
            value:"Suazilandia" 
                   },
            {

            label:"TC",
            value:"Turks and Caicos Islands" 
                   },
            {

            label:"TD",
            value:"Chad" 
                   },
            {

            label:"TF",
            value:"French Soutern Territories" 
                   },
            {

            label:"TG",
            value:"Togo" 
                   },
            {

            label:"TH",
            value:"Tailandia" 
                   },
            {

            label:"TJ",
            value:"Tayikistán" 
                   },
            {

            label:"TK",
            value:"Tokelau" 
                   },
            {

            label:"TL",
            value:"Timor Oriental" 
                   },
            {

            label:"TM",
            value:"Turkmenistán" 
                   },
            {

            label:"TN",
            value:"Túnez" 
                   },
            {

            label:"TO",
            value:"Tonga" 
                   },
            {

            label:"TR",
            value:"Turquía" 
                   },
            {

            label:"TT",
            value:"Trinidad y Tobago" 
                   },
            {

            label:"TV",
            value:"Tuvalu" 
                   },
            {

            label:"TW",
            value:"Taiwán" 
                   },
            {

            label:"TZ",
            value:"Tanzania" 
                   },
            {

            label:"UA",
            value:"Ucrania" 
                   },
            {

            label:"UG",
            value:"Uganda" 
                   },
            {

            label:"UK",
            value:"Reino Unido" 
                   },
            {

            label:"UM",
            value:"USA Minor Outlyng Islands" 
                   },
            {

            label:"US",
            value:"Estados Unidos" 
                   },
            {

            label:"UY",
            value:"Uruguay" 
                   },
            {

            label:"UZ",
            value:"Uzbekistán" 
                   },
            {

            label:"VA",
            value:"Vaticano" 
                   },
            {

            label:"VC",
            value:"Saint Vincent & Grenadines" 
                   },
            {

            label:"VE",
            value:"Venezuela" 
                   },
            {

            label:"VG",
            value:"Virgin Islands (UK)" 
                   },
            {

            label:"VI",
            value:"Virgin Islands (US)" 
                   },
            {

            label:"VN",
            value:"Vietnam" 
                   },
            {

            label:"VU",
            value:"Vanuatu" 
                   },
            {

            label:"WF",
            value:"Wallis and Fortuna Islands" 
                   },
            {

            label:"WS",
            value:"Samoa" 
                   },
            {

            label:"YE",
            value:"Yemen" 
                   },
            {

            label:"YT",
            value:"Mayotte" 
                   },
            {

            label:"ZA",
            value:"Sudáfrica" 
                   },
            {

            label:"ZM",
            value:"Zambia" 
                   },
            {

            label:"ZW",
            value:"Zimbawe"
			},
    ];


export default selectCountries ;
