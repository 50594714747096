import React, { useCallback } from 'react';

const StepButtons = ({ step, setStep, captchaValue, nextStepAction }) => {
    const nextStep = () => {
        switch (step) {
            case 4:
                return (
                    <button
                        id="lastButtonSubmit"
                        className={
                            'c-btn o-button-container__button ' +
                            (captchaValue === null ? 'button-disabled' : '')
                        }
                        onClick={() => nextStepAction()}
                    >
                        <span className="c-btn__text">Finalizar</span>
                    </button>
                );
            default:
                return (
                    <button
                        className="c-btn o-button-container__button"
                        onClick={() => nextStepAction()}
                    >
                        <span className="c-btn__text">Siguiente</span>
                    </button>
                );
        }
    };

    const handleBackButton = useCallback(() => {
        switch (step) {
            default:
                setStep(step - 1);
                break;
        }
    }, [setStep, step]);

    return (
        <div className="o-fieldset u-mt-large">
            <div className="o-fieldset__row">
                <div className="o-button-container  o-button-container--nav">
                    <button
                        className="c-btn c-btn--secondary o-button-container__button"
                        onClick={() => handleBackButton()}
                    >
                        <i className="c-icon c-icon--[semantic-back] c-icon--small"></i>
                        <span className="c-btn__text">Volver</span>
                    </button>
                    {nextStep()}
                </div>
            </div>
        </div>
    );
};

export default StepButtons;
