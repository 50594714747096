import React from 'react';

const Modal = ({
    hideModal,
    onToggle,
    showModalTitle,
    showModalFooter,
    modalContent,
}) => {
    return (
        <div className="c-modal  " aria-hidden={hideModal} id="demo-modal">
            <div
                className="c-modal__overlay  js-modal-close"
                tabIndex="-1"
            ></div>

            <div
                className="c-modal__wrapper"
                role="dialog"
                aria-labelledby="foo-bar-baz"
            >
                <header className="c-modal__header">
                    {showModalTitle ? (
                        <div className="c-modal__title__wrapper">
                            <h1 className="c-modal__title" id="foo-bar-baz">
                                Modal Title
                            </h1>
                        </div>
                    ) : null}
                    <button
                        className="c-modal__close-btn  js-modal-close"
                        type="button"
                        aria-label="Close this dialog window."
                        onClick={onToggle}
                    ></button>
                </header>

                <div className="c-modal__content u-text-left" role="document">
                    {modalContent()}
                </div>
                {showModalFooter ? (
                    <div className="c-modal__footer">
                        <div className="c-modal__actions">
                            <div className="c-modal__actions__item">
                                <button
                                    className="c-btn  js-modal-close  js-modal-autofocus"
                                    aria-label="Close this dialog window."
                                >
                                    <span className="c-btn__text">Confirm</span>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default Modal;
