import React from "react"

export default function privacyPolicyText() {
    return (
        <React.Fragment>
            <h4 className="u-text-center"> Política de privacidad </h4>
            <p>
                Ud. queda informado que los datos personales suministrados en el presente formulario y los que suministre en el futuro por cualquier medio o en virtud de otras comunicaciones o relaciones contractuales, incluyendo los resultantes de procesos informáticos derivados de los registrados, serán tratados en su calidad de responsable/s del tratamiento, por VOLKSWAGEN RENTING S.A con domicilio social todas ellas en Avda. de Bruselas, 34, 28108, Alcobendas (Madrid) con la finalidad de gestionar su solicitud de acceso a dicho/-s aplicativo/-s.
            </p>
            <p>
                En el caso de representantes de personas jurídicas, Ud, en dicha condición presta su consentimiento para que los datos personales contenidos en el registro así como aquellos necesarios para la prestación de los servicios puedan ser tratados por VOLKSWAGEN RENTING SA  con la finalidad de gestión y mantenimiento del aplicativo y de la relación contractual, en su caso.  Ud. podrá en cualquier momento ejercitar los derechos de acceso, rectificación, supresión, limitación en el tratamiento y oposición respecto a sus datos personales conforme se indica en el último párrafo.
            </p>
            <p>
                En el caso supuesto de que facilites datos de terceros, Ud.  manifiesta haber informado previamente a los mismos sobre el tratamiento de sus datos para las finalidades anteriormente recogidas,  todas  ellas  basadas  en  la  gestión  de  la  presente solicitud, de obligaciones legales y/o interés legítimo de VOLKSWAGEN RENTING S.A., circunstancia que deberá poder acreditar en caso de que así se le requiera.
            </p>
            <p>
                Adicionalmente, VOLKSWAGEN RENTING SA solicita su consentimiento para el tratamiento de sus datos con las siguientes finalidades:
            </p>
            <ul>
                <li>
                    Ceder sus datos a empresas pertenecientes al Grupo Volkswagen dentro de la Unión Europea, cuyas actividades se relacionan con los sectores antes indicados para el envío de publicidad por cualquier medio y en particular por medios de comunicación electrónica.
                </li>
            </ul>
            <p>
                Los datos personales tratados al amparo del presente y el cumplimiento del interés legítimo de VOLKSWAGEN RENTING S.A., así como aquellos prestados con su consentimiento , mientras no se solicite su supresión u oposición, se conservarán mientras conste dado de alta en el aplicativo, así como el tiempo necesario para cumplir las obligaciones legales.
            </p>
            <p>
                Usted podrá retirar el consentimiento en cualquier momento y/o ejercitar los derechos de acceso, rectificación o supresión, oposición y portabilidad de los datos, por correo electrónico a <a href="mailto:atención.cliente@vwfs.com"> atención.cliente@vwfs.com </a> . Asimismo, podrá solicitar la limitación del tratamiento de sus datos en los casos legalmente previstos. Igualmente, puede contactar con nuestro Delegado de Protección de Datos en la siguiente dirección <a href="mailto:DPO.es@vwfs.com"> DPO.es@vwfs.com </a>, así como dirigirse a la Agencia Española de Protección de Datos.
            </p>
        </React.Fragment>
    )
};