import AppService from './AppService';
import { config, configFile } from './Config';
import baseCoreServer from '../utils/switchCoreServer';

export async function LoginAWS() {
    let token = window.sessionStorage.getItem('cookieJWT');
    if (token) {
        let tokenParsed = JSON.parse(token);
        if (new Date(tokenParsed.AccessTokenExpiration) > new Date()) {
            return tokenParsed.AccessToken;
        }
    }
    /* userType = A (Autónomo/Empresa), E (Exportador) */
    var queryString =
        baseCoreServer() + process.env.REACT_APP_URL_LOGIN_AWS;
    var requestBody = {
        Body: JSON.stringify({ UserId: process.env.REACT_APP_JWT_USERID }),
    };

    var count = 5;

    return AppService.post(queryString, requestBody, config)
        .then(response => {
            //401 volver a pedir un token, aguanta 30 min
            if (response && response.data.statusCode === 200) {
                var body = JSON.parse(response.data.body);
                window.sessionStorage.setItem('cookieJWT', body.Result);
                return JSON.parse(body.Result).AccessToken;
            } else {
                //Volvemos a pedir el token para refrescarlo
                count--;
                if (count > 0) LoginAWS();
            }
        })
        .catch(function (error) {});
}

export async function GetUserCompanyInformation(hash, token) {
    var queryString =
        baseCoreServer() +
        process.env.REACT_APP_URL_GET_USER_COMPANY_INFO;
    var requestBody = { Body: JSON.stringify(hash) };

    var headers = config;
    headers.headers['Authorization'] = `Bearer ${token}`;

    return AppService.post(queryString, requestBody, headers)
        .then(response => {
            if (response && response.data && response.status === 200) {
                if (response.data.body) {
                    var result = response.data.body;
                    if (result.length > 0) return result;
                    else return null;
                }
            }
        })
        .catch(function (error) {});
}

export async function CheckInputUserData(userType, data, token) {
    /* userType = A (Autónomo/Empresa), E (Exportador) */
    var queryString =
        baseCoreServer() +
        process.env.REACT_APP_URL_CHECK_INPUT_USER_DATA;
    // var requestBody = { Body : JSON.stringify(JSON.stringify({ CompanyNif: data, ClientType: userType })) };
    var requestBody = {
        Body: JSON.stringify({ CompanyNif: data, ClientType: userType }),
    };

    var headers = config;
    headers.headers['Authorization'] = `Bearer ${token}`;

    return await AppService.post(queryString, requestBody, headers)
        .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
                if (response.data.body) {
                    var result = JSON.parse(
                        JSON.parse(response.data.body).Result
                    );
                    if (result) return true;
                    else return false;
                }
            }
        })
        .catch(function (error) {});
}

export async function InsertCompanyData(data, isUserBeingModified) {
    var queryString =
        baseCoreServer() +
        process.env.REACT_APP_URL_INSERT_COMPANY_DATA;

    var potentialClientData = { Documents: [] };

    debugger;

    if (data.companyData) {
        potentialClientData.CompanyNif = data.companyData.nifNie;
        potentialClientData.CompanyName = data.companyData.nombreEmpresa;
        potentialClientData.ClientType = data.tipoCliente;

        potentialClientData.PhysicalAddress = {
            StreetType:
                data.companyData['tipoVia' + data.tipoCliente + 'fisica'],
            StreetName:
                data.companyData['nombreVia' + data.tipoCliente + 'fisica'],
            Number: data.companyData['numero' + data.tipoCliente + 'fisica'],
            Floor: data.companyData['piso' + data.tipoCliente + 'fisica'],
            Door: data.companyData['puerta' + data.tipoCliente + 'fisica'],
            PostalCode: data.companyData['cp' + data.tipoCliente + 'fisica'],
            Province:
                data.companyData['provincia' + data.tipoCliente + 'fisica'],
            Country: data.companyData['pais' + data.tipoCliente + 'fisica'],
            Town: data.companyData['poblacion' + data.tipoCliente + 'fisica'],
        };

        if (data.sameAddressData) {
            potentialClientData.FiscalAddress =
                potentialClientData.PhysicalAddress;
        } else {
            potentialClientData.FiscalAddress = {
                StreetType:
                    data.companyData['tipoVia' + data.tipoCliente + 'fiscal'],
                StreetName:
                    data.companyData['nombreVia' + data.tipoCliente + 'fiscal'],
                Number: data.companyData[
                    'numero' + data.tipoCliente + 'fiscal'
                ],
                Floor: data.companyData['piso' + data.tipoCliente + 'fiscal'],
                Door: data.companyData['puerta' + data.tipoCliente + 'fiscal'],
                PostalCode:
                    data.companyData['cp' + data.tipoCliente + 'fiscal'],
                Province:
                    data.companyData['provincia' + data.tipoCliente + 'fiscal'],
                Country: data.companyData['pais' + data.tipoCliente + 'fiscal'],
                Town: data.companyData[
                    'poblacion' + data.tipoCliente + 'fiscal'
                ],
            };
        }

        potentialClientData.Mail = data.companyData.correo1Input;
        potentialClientData.OptionalMail = data.companyData.optionalEmail1Input;
        potentialClientData.Phone = data.companyData.telefonoInput;
        potentialClientData.OptionalPhone =
            data.companyData.telefonoOpcionalInput;
        potentialClientData.Web = data.companyData.webInput;
    } else {
        potentialClientData.CompanyNif = data.CompanyNif;
        potentialClientData.ClientType = data.ClientType;
    }

    potentialClientData.UserNif = data.userData.dniUsuario;
    potentialClientData.UserName = data.userData.nombreUsuario;
    potentialClientData.UserSurname = data.userData.pApellidoUsuario;
    potentialClientData.UserSurname2 = data.userData.sApellidoUsuario;
    potentialClientData.UserMail = data.userData.correo2Input;
    potentialClientData.UserPhone = data.userData.telefonoUsuario;
    potentialClientData.AcceptAds = data.AcceptAds;


    potentialClientData.BeneficialOwners=data.BeneficialOwners;
    potentialClientData.StepRegisterUser = data.stepRegisterUser;

    await Promise.all(
        data.Documents.map(async document => {
            var auxDocument = {
                File: await toBase64(document.file),
                DocumentType: document.tipoDoc,
                FileName: document.file.name,
            };

            potentialClientData.Documents.push(auxDocument);
        })
    );

    potentialClientData.isUserBeingModified = isUserBeingModified;

    var requestBody = { Body: JSON.stringify(potentialClientData) };
    
    debugger;

    var token = await LoginAWS();

    console.log(token);

    var headers = config;
    headers.headers['Authorization'] = `Bearer ${token}`;

    return AppService.post(queryString, requestBody, headers)
        .then(response => {
            debugger;
            console.log(response);
            if (response && response.data) {
                if (JSON.parse(response.data.body).Errors !== null && JSON.parse(response.data.body).Errors !== undefined && JSON.parse(response.data.body).Errors.length > 0) {
                    return JSON.parse(response.data.body);
                } else return JSON.parse(response.data.body).Result;
            }
        })
        .catch(function (error) {
            console.log(error);
        });
}

const toBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
