import React, { useState, useCallback, useEffect } from 'react';
import { CheckInputUserData, LoginAWS } from '../services/registerService';
import { ValidateSpanishID } from '../utils/validations';

const CheckingDataForm = ({
    nextStepForm,
    userType,
    setUserType,
    potentialClientData,
    setPotentialClientData,
    setIsLoading,
    nifCodigoEmpresa,
    setNifCodigoEmpresa
}) => {
    const [inputValue, setInputValue] = useState('');
    const [errorInputValue, setErrorInputValue] = useState('');
    const [inputError, setInputError] = useState('');
    const [inputSuccess, setInputSuccess] = useState(false);
    const [registerButton, setRegisterButton] = useState(false);

    const handleInputValidation = useCallback(() => {
        var validInput = true;
        
        if (inputValue !== '' && inputValue !== null) {
            validInput = ValidateSpanishID(inputValue);
            if (!validInput && userType === 'A') {
                setErrorInputValue('Los datos introducidos no son correctos.');
                setInputSuccess(false);
                setRegisterButton(false);
            } else if (userType === 'E') {
                setErrorInputValue('');
                setInputError('');
            } else {
                setErrorInputValue('');
            }
        }
    }, [setErrorInputValue, inputValue, setInputError, userType]);

    useEffect(() => {
        handleInputValidation();
    }, [userType, handleInputValidation]);

    const handleRegisterClick = useCallback(() => {
        setPotentialClientData({
            ...potentialClientData,
            ClientType: userType,
        });
        nextStepForm('newUserForm');
    }, [nextStepForm, potentialClientData, setPotentialClientData, userType]);

    const handleInputClick = useCallback(() => {
        var validInput = true;

        //debugger;

        setIsLoading(true);
        if (inputValue !== '' && inputValue !== null) {
            if (userType === 'A') {
                validInput = ValidateSpanishID(inputValue);
                if (!validInput) {
                    setErrorInputValue(
                        'Los datos introducidos no son correctos.'
                    );
                    setInputSuccess(false);
                    setRegisterButton(false);
                    setInputError('is-error');
                } else {
                    setErrorInputValue('');
                    setInputError('');
                }

                setIsLoading(false);
            }

            if (validInput) {
                LoginAWS()
                    .then(result => {
                        if (result && result.length > 0) {
                            CheckInputUserData(userType, inputValue, result)
                                .then(resultData => {
                                    setInputError('');
                                    if (resultData) {
                                        setPotentialClientData({
                                            ...potentialClientData,
                                            CompanyNif: inputValue,
                                        });
                                        setInputSuccess(true);
                                        setRegisterButton(true);

                                        handleRegisterClick();
                                    } else if (resultData === undefined) {
                                        setErrorInputValue(
                                            'Error al recuperar los datos.'
                                        );
                                        setInputSuccess(false);
                                        setRegisterButton(false);
                                    } else {
                                        setErrorInputValue(
                                            'La empresa no está dada de alta.'
                                        );
                                        setInputSuccess(false);
                                        setRegisterButton(false);
                                    }
                                })
                                .catch(error => {});
                        }

                        setIsLoading(false);
                    })
                    .catch(error => {});
            } else {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    }, [inputValue, potentialClientData, setPotentialClientData, userType, handleRegisterClick, setIsLoading]);

    const handleInputChange = useCallback(
        e => {
            setInputValue(e.target.value);
            setErrorInputValue('');
            setNifCodigoEmpresa(e.target.value);
        },
        [setErrorInputValue, setInputValue, setNifCodigoEmpresa]
    );

    const handleKeyPress = useCallback(e => {
        if (e.key === "Enter" || e.charCode === 13) {
            e.preventDefault();
        }
    }, []);

    const dataUser = useCallback(() => {
        switch (userType) {
            case 'A':
                return (
                    <div className="c-form-field u-mb">
                        <label
                            htmlFor="userDataInput"
                            className="c-form-field__label"
                        >
                            <strong> NIF/NIE de tu empresa </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div
                                className={
                                    inputSuccess
                                        ? 'c-input is-success'
                                        : 'c-input '
                                }
                            >
                                <input
                                    className={
                                        (inputSuccess
                                            ? 'c-input__input is-success'
                                            : 'c-input__input ') + inputError
                                    }
                                    id="userDataInput"
                                    type="text"
                                    placeholder=""
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    onChange={e => handleInputChange(e)}
                                    onKeyPress={e => handleKeyPress(e)}
                                />
                                {inputSuccess ? (
                                    <i
                                        className="c-input__state-icon"
                                        aria-hidden="true"
                                        role="img"
                                    ></i>
                                ) : null}
                            </div>
                            <p
                                className="c-error-message"
                                id="userDataInput-error"
                            >
                                {' '}
                                {errorInputValue}{' '}
                            </p>
                        </div>
                    </div>
                );
            case 'E':
                return (
                    <div className="c-form-field u-mb">
                        <label
                            htmlFor="userDataInput"
                            className="c-form-field__label"
                        >
                            <strong> Código de Identificación </strong>
                        </label>
                        <div className="c-form-field__box">
                            <div className="c-input  ">
                                <input
                                    className={
                                        (inputSuccess === true
                                            ? 'c-input__input is-success'
                                            : 'c-input__input ') + inputError
                                    }
                                    id="userDataInput"
                                    type="text"
                                    placeholder=""
                                    data-tr-component="Input Field"
                                    data-tr-function="Text Input"
                                    onChange={e => handleInputChange(e)}
                                />
                                {inputSuccess === true ? (
                                    <i
                                        className="c-input__state-icon"
                                        aria-hidden="true"
                                        role="img"
                                    ></i>
                                ) : null}
                            </div>
                            <p
                                className="c-error-message"
                                id="userDataInput-error"
                            >
                                {' '}
                                {errorInputValue}{' '}
                            </p>
                        </div>
                    </div>
                );
            default:
                break;
        }
    }, [
        errorInputValue,
        handleInputChange,
        inputError,
        inputSuccess,
        userType,
    ]);

    const buttonSwitch = useCallback(() => {
        switch (registerButton) {
            case false:
                return (
                    <button
                        className="c-btn c-btn--secondary"
                        onClick={() => handleInputClick()}
                    >
                        <span className="c-btn__text">Comprobar</span>
                    </button>
                );
            case true:
                return (
                    <button
                        className="c-btn c-btn--secondary"
                        onClick={() => handleRegisterClick()}
                    >
                        <span className="c-btn__text">Registrar usuario</span>
                    </button>
                );
            default:
                break;
        }
    }, [registerButton, handleInputClick, handleRegisterClick]);

    const userTypeClick = useCallback(
        type => {
            setUserType(type);
        },
        [setUserType]
    );

    return (
        <React.Fragment>
            <div className="o-layout">
                <div className="o-layout__item">
                    <div className="c-form-field">
                        <p>
                            <strong> Alta de empresa </strong>
                        </p>
                        <p>
                            {' '}
                            Para dar de alta tu empresa debes completar el
                            registro{' '}
                        </p>
                    </div>
                </div>
            </div>
            <div className="o-layout o-layout--right u-mb">
                <div className="o-layout__item u-4/12 u-6/12@s u-12/12@xs">
                    <div className="c-form-field">
                        <button
                            className="c-btn"
                            onClick={() => nextStepForm('checkingDataForm')}
                        >
                            <span className="c-btn__text">
                                Registrar empresa
                            </span>
                        </button>
                    </div>
                </div>
            </div>
            <hr />
            <div className="o-layout">
                <div className="o-layout__item">
                    <div className="c-form-field">
                        <p>
                            <strong> Alta de usuario </strong>
                        </p>
                        <p>
                            {' '}
                            Si ya está dada de alta tu empresa introduce los
                            siguientes datos:{' '}
                        </p>
                    </div>
                </div>
                <div className="o-layout__item">
                    <div className="c-form-field u-mb">
                        <div className="c-form-field__box">
                            <fieldset>
                                <div className="o-inline-group">
                                    <span className="o-inline-group__item">
                                        <label className="c-radio">
                                            <input
                                                className="c-radio__input "
                                                type="radio"
                                                name="user-type-radio"
                                                defaultChecked={
                                                    userType === 'A'
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => {
                                                    userTypeClick('A');
                                                }}
                                            />
                                            <span className="c-radio__label">
                                                {' '}
                                                Autónomo/Empresa{' '}
                                            </span>
                                        </label>
                                    </span>
                                    <span className="o-inline-group__item">
                                        <label className="c-radio">
                                            <input
                                                className="c-radio__input  "
                                                type="radio"
                                                name="user-type-radio"
                                                defaultChecked={
                                                    userType === 'E'
                                                        ? true
                                                        : false
                                                }
                                                onClick={() => {
                                                    userTypeClick('E');
                                                }}
                                            />
                                            <span className="c-radio__label">
                                                {' '}
                                                Exportador{' '}
                                            </span>
                                        </label>
                                    </span>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                    {dataUser()}
                </div>
            </div>
            <div className="o-layout o-layout--right u-mb">
                <div className="o-layout__item u-4/12 u-4/12@s u-12/12@xs">
                    <div className="c-form-field">{buttonSwitch()}</div>
                </div>
            </div>
            <span>
                {' '}
                *Para dar de alta usuarios y concesionarios del grupo, por favor
                ponte en contacto con el delegado correspondiente.{' '}
            </span>
        </React.Fragment>
    );
};

export default CheckingDataForm;
