import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import FormPage from './Pages/FormPage';
import SuccessPage from './Pages/SuccessPage';
import Globals from './Globals';

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Switch>
                    <Route
                        exact
                        path={Globals.APPLICATION_PATH.ROOT}
                        component={FormPage}
                    />
                    <Route
                        exact
                        path={Globals.APPLICATION_PATH.COMPLETED}
                        component={SuccessPage}
                    />
                </Switch>
            </BrowserRouter>
        </div>
    );
}

export default App;
