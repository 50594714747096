import React, { useState, useCallback, useEffect } from 'react';
import DownloadButton from './DownloadButton';
import mime from 'mime-types';

const UploadInline = ({
    nombreFicheroASubir,
    tipoDoc,
    potentialClientData,
    setPotentialClientData,
}) => {
    const [files, setFiles] = useState([]);
    const [firstLoad, setFirstLoad] = useState(true);
    const [fileExtensionError, setFileExtensionError] = useState(null);

    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    };

    useEffect(() => {
        setFirstLoad(false);
        if (files && files.length > 0) {
            let newArr = potentialClientData;
            if (!Array.isArray(newArr.Documents)) newArr.Documents = [];

            let index = newArr.Documents.indexOf(
                newArr.Documents.find(x => x.FileName === files[0].name)
            );
            if (index >= 0) {
                newArr.Documents[index] = {
                    tipoDoc: tipoDoc,
                    file: files[0],
                };
            } else {
                newArr.Documents.push({
                    tipoDoc: tipoDoc,
                    file: files[0],
                });
            }

            setPotentialClientData(newArr);
        }

        if (
            firstLoad &&
            potentialClientData &&
            potentialClientData.Documents &&
            potentialClientData.Documents.length > 0
        ) {
            //Convertimos el formato del fichero que viene del CORE al que el input interpreta
            let newFiles = [];
            for (var i = 0; i < potentialClientData.Documents.length; i++) {
                if (potentialClientData.Documents[i].DocumentType === tipoDoc) {
                    var fileName = potentialClientData.Documents[i].FileName;
                    var extension = fileName.split('.');
                    var newFile = dataURLtoFile(
                        'data:' +
                            mime.lookup(extension[1]) +
                            ';base64,' +
                            potentialClientData.Documents[i].File,
                        fileName
                    );

                    newFiles.push(newFile);
                }
            }
            setFiles(newFiles);
        }
    }, [
        files,
        setPotentialClientData,
        potentialClientData,
        tipoDoc,
        firstLoad,
    ]);

    const handleInputChange = useCallback(e => {
        e.preventDefault();
        if (e?.currentTarget) {
            if (e.currentTarget.files.length > 0) {
                let currentFile = e.currentTarget.files[0];
                let extension = currentFile.name.split('.')[
                    currentFile.name.split('.').length - 1
                ];
                let extensionValid = ['png', 'jpg', 'doc', 'pdf'].includes(
                    extension
                );
                setFileExtensionError(
                    !extensionValid
                        ? 'Debes seleccionar un tipo de documento válido.'
                        : null
                );
                if (extensionValid) {
                    let newArr = [];
                    newArr[newArr.length] = currentFile;

                    setFiles(newArr);
                }
            }
        }
    }, []);

    const removeFile = useCallback(() => {
        debugger;
        let index = potentialClientData.Documents.indexOf(
            potentialClientData.Documents.find(
                x => x.tipoDoc === tipoDoc
            )
        );
        if (index >= 0) {
            let newPotentialClientData = potentialClientData;
            newPotentialClientData.Documents = newPotentialClientData.Documents.filter(
                item => item !== newPotentialClientData.Documents[index]
            );
            setFiles([]);
            setPotentialClientData(newPotentialClientData);
        }
    }, [files, potentialClientData, setPotentialClientData]);

    return (
        <React.Fragment>
            <div className="c-upload-inline">
                <span
                    id="nombre-fichero-span"
                    className="c-upload-inline__label labelFileName u-hide@s-up"
                    style={{ minWidth: '50%' }}
                >
                    {nombreFicheroASubir}
                </span>
                <label className="c-upload-inline__btn  c-btn">
                    Subir archivo
                    <input
                        className="c-upload-inline__input"
                        type="file"
                        onChange={e => handleInputChange(e)}
                        onClick={event => {
                            event.target.value = null;
                        }}
                    />
                </label>
                <span
                    id="nombre-fichero-span"
                    className="c-upload-inline__label labelFileName u-hide@xs"
                >
                    {nombreFicheroASubir}
                </span>
                <p className="c-error-message"> {fileExtensionError} </p>
            </div>
            <ul className="c-upload-container__itemlist u-mt-small">
                {Object.keys(files).map(function (key, index) {
                    return (
                        <li
                            id={'li-file-' + index}
                            key={index}
                            className="c-upload-container__item"
                        >
                            <div className="c-upload-item  ">
                                <i
                                    className="c-upload-item__icon  c-icon  c-icon--[semantic-file]"
                                    aria-hidden="true"
                                    role="img"
                                ></i>
                                <div className="c-upload-item__content">
                                    <div className="c-upload-item__title-row">
                                        <p className="c-upload-item__title">
                                            {' '}
                                            {files[key].name}{' '}
                                        </p>
                                        <div className="c-upload-item__interaction-icons">
                                            <DownloadButton file={files[key]} />
                                            <button
                                                className="c-upload-item__interaction-icon  c-upload-item__interaction-icon--secondary"
                                                onClick={removeFile}
                                            ></button>
                                            <i
                                                className="c-upload-item__status-icon"
                                                aria-hidden="true"
                                                role="img"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </React.Fragment>
    );
};

export default UploadInline;
