export const config = {
    headers: {
        'content-type': 'application/json',
        ErrorCode: 'FRM',
    },
};

export const configFile = {
    headers: {
        'content-type': 'application/json',
        ErrorCode: 'FRM',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
    },
};

export const configXml = {
    headers: {
        'Content-Type': 'text/xml;charset=UTF-8',
        SOAPAction: 'http://tempuri.org/IWSBase/IndexDocument',
        'Access-Control-Allow-Origin': '*',
    },
};
