import AppService from './AppService';
import { config } from './Config';
import baseCoreServer from '../utils/switchCoreServer';

export async function GetProvinces(data) {
    var queryString =
        baseCoreServer() +
        process.env.REACT_APP_URL_GET_PROVINCES_LIST;
    var requestBody =
        data !== null && data !== undefined
            ? { Body: JSON.stringify(data) }
            : {};

    return AppService.post(queryString, requestBody, config)
        .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
                if (response.data.body) {
                    return JSON.parse(response.data.body).Result;
                }
            }
        })
        .catch(function (error) {});
}

export async function GetVillages(data) {
    var queryString =
        baseCoreServer() +
        process.env.REACT_APP_URL_GET_VILLAGES_LIST;
    var requestBody =
        data !== null && data !== undefined
            ? { Body: JSON.stringify(data) }
            : {};

    return AppService.post(queryString, requestBody, config)
        .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
                if (response.data.body) {
                    return JSON.parse(response.data.body).Result;
                }
            }
        })
        .catch(function (error) {});
}

export async function GetCountries() {
    let countriesList = window.sessionStorage.getItem('CountriesList');
    if (countriesList) {
        return Promise.resolve(countriesList);
    }
    var queryString =
        baseCoreServer() +
        process.env.REACT_APP_URL_GET_COUNTRIES_LIST;

    return AppService.post(queryString, {}, config)
        .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
                if (response.data.body) {
                    window.sessionStorage.setItem(
                        'CountriesList',
                        JSON.parse(response.data.body).Result
                    );
                    return JSON.parse(response.data.body).Result;
                }
            }
        })
        .catch(function (error) {});
}

export async function GetRoads() {
    let roadList = window.sessionStorage.getItem('RoadList');
    if (roadList) {
        return Promise.resolve(roadList);
    }
    var queryString =
        baseCoreServer() +
        process.env.REACT_APP_URL_GET_ROADS_LIST;

    return AppService.post(queryString, {}, config)
        .then(response => {
            if (response && response.data && response.data.statusCode === 200) {
                if (response.data.body) {
                    window.sessionStorage.setItem(
                        'RoadList',
                        JSON.parse(response.data.body).Result
                    );
                    return JSON.parse(response.data.body).Result;
                }
            }
        })
        .catch(function (error) {});
}
