import React, { useState, useCallback, useEffect } from 'react';
import StepperNav from './StepperNav';
import AddressInputs from './AddressInputs';
import UploadInline from './UploadInline';
import {
    ValidateSpanishID,
    validateEmail,
    stringIsNullOrEmpty,
    ValidateSpanishIDNoCif,
    postalCodeValidation
} from '../utils/validations';
import { InsertCompanyData } from '../services/registerService';
import ReCAPTCHA from 'react-google-recaptcha';
import '../css/newCompanyForm.css';
import inputValidations, { validateInput } from '../utils/inputsValidations';
import StepButtons from './StepButtons';
import { GetRoads } from '../services/listsService';
import Modal from './Modal';
import SuccessPage from '../Pages/SuccessPage';
import privacyPolicyText from '../utils/texts';
import selectCountries from '../utils/selectCountries';

let validations = inputValidations.validations;

const NewCompanyForm = ({
    userType,
    setUserType,
    setIsLoading,
    potentialClientData,
    setPotentialClientData,
    isUserBeingModified,
}) => {
    const [step, setStep] = useState(1);
    const [sameAddressData, setSameAddressData] = useState(false);
    const [uploadDocumentsError, setUploadDocumentsError] = useState('');

    const [userName, setUserName] = useState('');
    const [userSurname1, setUserSurname1] = useState('');
    const [userSurname2, setUserSurname2] = useState('');

    const [phone1, setPhone1] = useState('');
    const [phone2, setPhone2] = useState('');
    const [phoneUsuario, setPhoneUsuario] = useState('');

    const [userAcceptAd, setUserAcceptAd] = useState('N');
    const [hideModalPolicy, setHideModalPolicy] = useState(true);

    const [nifNieError, setNifNieError] = useState(null);
    const [nombreEmpresaError, setNombreEmpresaError] = useState(null);

    /* Dirección Física */
    const [tipoViaFisicaError, setTipoViaFisicaError] = useState(null);
    const [nombreViaFisicaError, setNombreViaFisicaError] = useState(null);
    const [cpFisicaError, setCpFisicaError] = useState(null);
    const [provinciaFisicaError, setProvinciaFisicaError] = useState(null);
    const [poblacionFisicaError, setPoblacionFisicaError] = useState(null);

    /* Dirección Fiscal */
    const [tipoViaFiscalError, setTipoViaFiscalError] = useState(null);
    const [nombreViaFiscalError, setNombreViaFiscalError] = useState(null);
    const [cpFiscalError, setCpFiscalError] = useState(null);
    const [provinciaFiscalError, setProvinciaFiscalError] = useState(null);
    const [poblacionFiscalError, setPoblacionFiscalError] = useState(null);

    /* Exportadores */
    const [paisFisicaError, setPaisFisicaError] = useState(null);
    const [direccionFisicaError, setDireccionFisicaError] = useState(null);
    const [direccionFiscalError, setDireccionFiscalError] = useState(null);

    const [email1Error, setEmail1Error] = useState(null);
    const [telefonoError, setTelefonoError] = useState(null);

    /* Usuario */
    const [nifNieUserError, setNifNieUserError] = useState(null);
    const [nombreUserError, setNombreUserError] = useState(null);
    const [pApellidoError, setPApellidoError] = useState(null);
    const [sApellidoError, setSApellidoError] = useState(null);
    const [correoUserError, setCorreoUserError] = useState(null);
    const [telefonoUserError, setTelefonoUserError] = useState(null);

    /* Titular Real */
    const [titularesReales, setTitularesReales] = useState(null);
    const [errorGeneralTitularesReales, setErrorGeneralTitularesReales] = useState(null);

    /* Documentos */
    const [dniDocValidation, setDniDocValidation] = useState(true);
    const [iaeDocValidation, setIaeDocValidation] = useState(true);
    const [ivaDocValidation, setIvaDocValidation] = useState(true);
    const [cifDocValidation, setcifDocValidation] = useState(true);

    const [privacyPolicy, setPrivacyPolicy] = useState(false);
    const [roadsList, setRoadsList] = useState([]);

    const [captchaValue, setCaptchaValue] = useState(null);

    const [documentsCopyUserModification, setDocumentsCopyUserModification] =useState(potentialClientData ? potentialClientData.Documents : null);



    const ConvertRegister = useCallback(items => {
        const array = [];
        const ass = items?.forEach(x => {
            const obj = {};
            obj.IdTitularReal=x.IdTitularReal;
            obj.NifTitularReal = x.NifTitularReal;
            obj.NombreTitularReal = x.NombreTitularReal;
            obj.DateBornTitularReal = x.DateBornTitularReal;
            obj.CountryBornTitularReal = x.CountryBornTitularReal;
            obj.CountryExpeDocTitularReal = x.CountryExpeDocTitularReal;
            obj.NifTitularRealError = x.NifTitularRealError;
            obj.NombreTitularRealError = x.NombreTitularRealError;
            obj.DateBornTitularRealError = x.DateBornTitularRealError;
            obj.CountryBornTitularRealError = x.CountryBornTitularRealError;
            obj.CountryExpeDocTitularRealError = x.CountryExpeDocTitularRealError;
            array.push(obj);
        });
        return array;
    });    

    const hideFiscalAddress = () => {
        document.getElementById('fiscalAddress').style.display = 'none';
    };

    const showFiscalAddress = () => {
        document.getElementById('fiscalAddress').style.display = 'block';
    };

    const setSameDataInFiscalAddress = useCallback(
        event => {
            setSameAddressData(event.currentTarget.checked);
        },
        [setSameAddressData]
    );

    useEffect(() => {
        setNifNieError(null);
        setTipoViaFisicaError(null);
        setTipoViaFiscalError(null);
        setNombreViaFisicaError(null);
        setNombreViaFiscalError(null);
        setCpFisicaError(null);
        setCpFiscalError(null);
        setProvinciaFisicaError(null);
        setProvinciaFiscalError(null);
        setPoblacionFisicaError(null);
        setPoblacionFiscalError(null);
        setPaisFisicaError(null);
        setDireccionFisicaError(null);
        setDireccionFiscalError(null);

        if (document.getElementById("nifNie") && potentialClientData.CompanyNif === "") {
            document.getElementById("nifNie").value = "";
        }
    }, [userType]);

    // useEffect(() => {
    //     if (step === 3) {
    //         potentialClientData.Documents = [];
    //     }
    // }, [potentialClientData.Documents, step]);

    useEffect(() => {
        if (step === 1) {
            if (sameAddressData) {
                hideFiscalAddress();
            } else {
                showFiscalAddress();
            }
        }
    }, [sameAddressData, step]);

    useEffect(() => {
        GetRoads()
            .then(result => {
                if (result) {
                    setRoadsList(JSON.parse(result));
                }
            })
            .catch(error => {});
    }, [setRoadsList]);

    
const DateInput = ({
    name,
    onChange,
    id,
    value,
}) => {
    useEffect(() => {
        window.bronson.$$(`#${name}`).on('bronson_datepickerChange', event => {
            onChange(event);
        });
    }, [name, onChange]);
    useEffect(() => {
        window.bronson.$$(`#${name}`).component('datepicker');
    }, []);

    return (
        <React.Fragment>
                <div
                    className={
                        'c-accordion__content' + name
                    }
                >
                    <div
                        id={id}
                        className="c-datepicker js-datepicker"
                        data-datepicker-month-selector-type="dropdown"
                        data-datepicker-date-format="d/m/Y"
                        data-datepicker-disable-mobile="true"
                    >
                        <div className="c-input">
                            <input
                                className="c-input__input flatpickr-input active"
                                data-tr-component="Input Field"
                                type="text"
                                data-tr-function="Text Input"
                                onChange={e => onChange(e)}
                                name={name}
                                value={value}
                                data-input
                            />
                            <button
                                className="c-input__addon  c-input__addon--no-background"
                                aria-label="Toggle  datepicker"
                                type="button"
                                data-toggle
                                style={{ paddingRight: 0 }} //ICON TO RIGHT, SAME SPACE AS CHEVRON FOR SELECTS
                            >
                                <i
                                    className="c-icon  c-icon--[semantic-calendar]"
                                    aria-hidden="true"
                                    role="img"
                                />
                            </button>
                        </div>
                    </div>
                </div>
        </React.Fragment>
    );
};



const SelectInput = ({
    options,
    onChange,
    name,
    id,
    value,
}) => {
    const renderOptions = useCallback(() => {
        return options.map((option, i) => (
            <option key={i} value={option.label}>
                {option.value}
            </option>
        ));
    }, [options]);
    
    return (
        <React.Fragment>
            <div
                    className={
                        'c-accordion__content' + name
                    }
                >
                    <div className="c-input  c-input--select">
                        <select
                            className="c-input__input"
                            name={name}
                            id={id}
                            value={value}
                            selected={value}
                            onChange={e => {
                                onChange(e);
                            }}
                            
                        >
                            <option
                                key=""
                                value=""
                            ></option>
                            {renderOptions()}
                        </select>
                    </div>
                </div>
        </React.Fragment>
    );
};

    const insertCompanyData = useCallback(() => {
        setIsLoading(true);

        InsertCompanyData(potentialClientData, isUserBeingModified)
            .then(result => {
                if (result === "true" || result === true) {
                    setStep(5);
                } else {
                    if (result && result.Errors && result.Errors.length > 0) {
                        alert(result.Errors[0].Value);
                    } else {
                        alert("Ha habido un error inesperado");
                    }
                }

                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }, [isUserBeingModified, potentialClientData, setIsLoading]);

    const handleDocumentsUploadedValidation = useCallback(() => {
        //debugger;
        if (potentialClientData?.Documents?.length >>> 0 && privacyPolicy) {
            let DNI = potentialClientData.Documents.filter(item => {
                return (
                    item.tipoDoc ===
                    process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_ID
                );
            });
            let IAE = potentialClientData.Documents.filter(item => {
                return (
                    item.tipoDoc ===
                    process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_IAE
                );
            });
            let CIF = potentialClientData.Documents.filter(item => {
                return (
                    item.tipoDoc ===
                    process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_CIF
                );
            });
            let TitReal = potentialClientData.Documents.filter(item => {
                return (
                    item.tipoDoc ===
                    process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_TITULAR_REAL
                );
            });
            if (isUserBeingModified) {
                if (document.getElementById("liIdDoc") !== null && DNI.length <= 0) {
                    setUploadDocumentsError('Debes subir el archivo DNI');
                }
                else if (document.getElementById("liIaeDoc") !== null && IAE.length <= 0) {
                    setUploadDocumentsError('Debes subir el archivo IAE');
                }
                else if (document.getElementById("liCifDoc") !== null && CIF.length <= 0) {
                    setUploadDocumentsError('Debes subir el archivo CIF');
                }
                else if (document.getElementById("liTitDoc") !== null && TitReal.length <= 0) {
                    setUploadDocumentsError('Debes subir el archivo de Certificado de titularidad real');
                }
                else {
                    setUploadDocumentsError('');
                    insertCompanyData();                    
                }
            } else if (DNI.length > 0 && IAE.length > 0 && CIF.length > 0 && TitReal.length > 0) {
                setUploadDocumentsError('');
                insertCompanyData();
            } else {
                setUploadDocumentsError(
                    'Debes subir al menos los cuatro archivos obligatorios (NIF/NIE, IAE y CIF Empresa y Certificado de titularidad real)'
                );
            }
        } else {
            setUploadDocumentsError(null);
            if (!privacyPolicy) {
                setUploadDocumentsError(
                    'Debes de aceptar la política de privacidad'
                );
            }

            if (isUserBeingModified) {
                if (dniDocValidation || iaeDocValidation || ivaDocValidation || cifDocValidation) setUploadDocumentsError('Debes de subir algún documento');
            } else if (!potentialClientData?.Documents || potentialClientData?.Documents?.length === 0) {
                setUploadDocumentsError('Debes de subir algún documento');
            }
        }
    }, [
        setUploadDocumentsError,
        privacyPolicy,
        insertCompanyData,
        potentialClientData,
    ]);

    const inputsNecessaryFilled = useCallback(() => {
        //Validación de que los inputs obligatorios están rellenados
        var validated = [];

        if (step === 1) {
            if (userType === 'A') {
                validated.push(
                    validateInput(
                        'nifNie',
                        setNifNieError,
                        validations.NIFNIE,
                        ValidateSpanishID
                    )
                );
            } 
            validated.push(
                validateInput(
                    'nombreEmpresa',
                    setNombreEmpresaError,
                    validations.NOMBREEMPRESA
                )
            );
            //Dirección física
            if (userType === 'A') {
                validated.push(
                    validateInput(
                        'tipoVia' + userType + 'fisica',
                        setTipoViaFisicaError,
                        validations.TIPOVIA
                    )
                );
                validated.push(
                    validateInput(
                        'nombreVia' + userType + 'fisica',
                        setNombreViaFisicaError,
                        validations.NOMBREVIA
                    )
                );
                validated.push(
                    validateInput(
                        'cp' + userType + 'fisica',
                        setCpFisicaError,
                        validations.CP,
                        postalCodeValidation,
                        userType
                    )
                );
                validated.push(
                    validateInput(
                        'provincia' + userType + 'fisica',
                        setProvinciaFisicaError,
                        validations.PROVINCIA
                    )
                );
                validated.push(
                    validateInput(
                        'poblacion' + userType + 'fisica',
                        setPoblacionFisicaError,
                        validations.POBLACION
                    )
                );
            } else {
                validated.push(
                    validateInput(
                        'nombreVia' + userType + 'fisica',
                        setDireccionFisicaError,
                        validations.DIRECCION
                    )
                );
                validated.push(
                    validateInput(
                        'poblacion' + userType + 'fisica',
                        setPoblacionFisicaError,
                        validations.POBLACION
                    )
                );
                validated.push(
                    validateInput(
                        'cp' + userType + 'fisica',
                        setCpFisicaError,
                        validations.CP,
                        postalCodeValidation,
                        userType
                    )
                );
            }

            if (!sameAddressData) {
                //Dirección fiscal

                if (userType === 'A') {
                    validated.push(
                        validateInput(
                            'tipoVia' + userType + 'fiscal',
                            setTipoViaFiscalError,
                            validations.TIPOVIA
                        )
                    );
                    validated.push(
                        validateInput(
                            'nombreVia' + userType + 'fiscal',
                            setNombreViaFiscalError,
                            validations.NOMBREVIA
                        )
                    );
                    validated.push(
                        validateInput(
                            'cp' + userType + 'fiscal',
                            setCpFiscalError,
                            validations.CP,
                            postalCodeValidation,
                            userType
                        )
                    );
                    validated.push(
                        validateInput(
                            'provincia' + userType + 'fiscal',
                            setProvinciaFiscalError,
                            validations.PROVINCIA
                        )
                    );
                    validated.push(
                        validateInput(
                            'poblacion' + userType + 'fiscal',
                            setPoblacionFiscalError,
                            validations.POBLACION
                        )
                    );
                } else {
                    validated.push(
                        validateInput(
                            'nombreVia' + userType + 'fiscal',
                            setDireccionFiscalError,
                            validations.DIRECCION
                        )
                    );
                    validated.push(
                        validateInput(
                            'poblacion' + userType + 'fiscal',
                            setPoblacionFiscalError,
                            validations.POBLACION
                        )
                    );
                    validated.push(
                        validateInput(
                            'cp' + userType + 'fiscal',
                            setCpFiscalError,
                            validations.CP,
                            postalCodeValidation,
                            userType
                        )
                    );
                }
            }

            validated.push(
                validateInput(
                    'correo1Input',
                    setEmail1Error,
                    validations.CORREO,
                    validateEmail
                )
            );
            validated.push(
                validateInput(
                    'telefonoInput',
                    setTelefonoError,
                    validations.TELEFONO
                )
            );
        } else if (step === 2) {
            validated.push(
                validateInput(
                    'dniUsuario',
                    setNifNieUserError,
                    validations.NIFNIE,
                    ValidateSpanishIDNoCif
                )
            );
            validated.push(
                validateInput(
                    'nombreUsuario',
                    setNombreUserError,
                    validations.NOMBRE
                )
            );
            validated.push(
                validateInput(
                    'pApellidoUsuario',
                    setPApellidoError,
                    validations.APELLIDO
                )
            );
            validated.push(
                validateInput(
                    'correo2Input',
                    setCorreoUserError,
                    validations.CORREO
                )
            );
            validated.push(
                validateInput(
                    'telefonoUsuario',
                    setTelefonoUserError,
                    validations.TELEFONO
                )
            );
        } else if (step === 3) { 
            const array = [...titularesReales];
            for (var i = 0; i < array.length; i++) 
            {
    
                if (!stringIsNullOrEmpty(array[i].NifTitularRealError)) 
                {
                    return false;
                }
                else if (!stringIsNullOrEmpty(array[i].NombreTitularRealError)) 
                {
                    return false;
                }
                else if (!stringIsNullOrEmpty(array[i].DateBornTitularRealError)) 
                {
                    return false;
                }
                else if (!stringIsNullOrEmpty(array[i].CountryBornTitularRealError)) 
                {
                    return false;
                }
                else if (!stringIsNullOrEmpty(array[i].CountryExpeDocTitularRealError)) 
                {
                    return false;
                }
                validated.push(
                    validateInput(
                        'nifTitularReal' + array[i].IdTitularReal,
                        setErrorGeneralTitularesReales,
                        "Algún campo obligatorio se encuentra vacio",
                        ValidateSpanishIDNoCif)
                );
                validated.push(
                    validateInput(
                        'nombreTitularReal' + array[i].IdTitularReal,
                        setErrorGeneralTitularesReales,
                        "Algún campo obligatorio se encuentra vacio"
                    )
                );
                if(array[i].DateBornTitularReal === '' || array[i].DateBornTitularReal === undefined){
                    setErrorGeneralTitularesReales("Algún campo obligatorio se encuentra vacio");
                }
                if(array[i].CountryBornTitularReal === '' || array[i].CountryBornTitularReal === undefined){
                    setErrorGeneralTitularesReales("Algún campo obligatorio se encuentra vacio");
                }
                if(array[i].CountryExpeDocTitularReal === '' || array[i].CountryExpeDocTitularReal === undefined){
                    setErrorGeneralTitularesReales("Algún campo obligatorio se encuentra vacio");
                }
                
            }                      
        }
        else {
            return true;
        }
        return validated.indexOf(false) === -1;
    }, [sameAddressData, step, userType,titularesReales]);

    const addTitularReal = () => {
        setTitularesReales((titularesReales) => [...titularesReales, {
            IdTitularReal: titularesReales.length + 1,
            NifTitularReal: "NifTitularReal" + titularesReales.length + 1,
            NombreTitularReal: "",
            DateBornTitularReal: "",
            CountryBornTitularReal: "",
            CountryExpeDocTitularReal: "",
            NifTitularRealError: "",
            NombreTitularRealError: "",
            DateBornTitularRealError: "",
            CountryBornTitularRealError: "",
            CountryExpeDocTitularRealError: "",
        }]);
    };
    const eliminarTitularReal = () => {
        const array = [...titularesReales];
        
        if(array.length >1) 
        {
            array.pop();
        }
        setTitularesReales(array);
    };

    const setErrorNifTitularReal = (id) => {
        const array = [...titularesReales];
        for (var i = 0; i < array.length; i++) {

            if (array[i].IdTitularReal == id) 
            {
                array[i].NifTitularRealError = validations.NIFNIE;
            }
        }
        setTitularesReales(array);
    };
    const borrarErrorNifTitularReal = (id) => {
        const array = [...titularesReales];
        for (var i = 0; i < array.length; i++) {

            if (array[i].IdTitularReal == id) 
            {
                array[i].NifTitularRealError = "";
            }
        }
        setTitularesReales(array);
    };

    const updatePotentialClientData = useCallback(() => {
        var inputs = document.forms['main-form'].getElementsByTagName('input');
        var selects =
            document.forms['main-form'].getElementsByTagName('select');

        if (inputsNecessaryFilled()) {
            let potentialClientDataAux = potentialClientData;
            switch (step) {
                case 1:
                    potentialClientDataAux.sameAddressData = sameAddressData;
                    potentialClientDataAux.tipoCliente = userType;
                    potentialClientDataAux.companyData = [];

                    Array.from(inputs).forEach(element => {
                        if (
                            element &&
                            element.id !== null &&
                            element.id !== undefined &&
                            element.id !== ''
                        ) {
                            potentialClientDataAux.companyData[
                                element.id.replace('-' + userType, '')
                            ] = element.value;
                        }
                    });

                    Array.from(selects).forEach(element => {
                        if (
                            element &&
                            element.id !== null &&
                            element.id !== undefined &&
                            element.id !== ''
                        ) {
                            potentialClientDataAux.companyData[
                                element.id.replace('-' + userType, '')
                            ] = element.value;
                        }
                    });

                    potentialClientDataAux.CompanyNif =
                        potentialClientDataAux.companyData.nifNie;
                    potentialClientDataAux.CompanyName =
                        potentialClientDataAux.companyData.nombreEmpresa;
                    potentialClientDataAux.ClientType =
                        potentialClientDataAux.tipoCliente;

                    potentialClientDataAux.PhysicalAddress = {
                        StreetType:
                            potentialClientDataAux.companyData[
                                'tipoVia' +
                                    potentialClientDataAux.tipoCliente +
                                    'fisica'
                            ],
                        StreetName:
                            potentialClientDataAux.companyData[
                                'nombreVia' +
                                    potentialClientDataAux.tipoCliente +
                                    'fisica'
                            ],
                        Number: potentialClientDataAux.companyData[
                            'numero' +
                                potentialClientDataAux.tipoCliente +
                                'fisica'
                        ],
                        Floor: potentialClientDataAux.companyData[
                            'piso' +
                                potentialClientDataAux.tipoCliente +
                                'fisica'
                        ],
                        Door: potentialClientDataAux.companyData[
                            'puerta' +
                                potentialClientDataAux.tipoCliente +
                                'fisica'
                        ],
                        PostalCode:
                            potentialClientDataAux.companyData[
                                'cp' +
                                    potentialClientDataAux.tipoCliente +
                                    'fisica'
                            ],
                        PostalCodeExporter:
                            potentialClientDataAux.companyData[
                                'cp' +
                                    potentialClientDataAux.tipoCliente +
                                    'fisica'
                            ],
                        Province:
                            potentialClientDataAux.companyData[
                                'provincia' +
                                    potentialClientDataAux.tipoCliente +
                                    'fisica'
                            ],
                        Country:
                            potentialClientDataAux.companyData[
                                'pais' +
                                    potentialClientDataAux.tipoCliente +
                                    'fisica'
                            ],
                        Town: potentialClientDataAux.companyData[
                            'poblacion' +
                                potentialClientDataAux.tipoCliente +
                                'fisica'
                        ],
                        TownExporter: potentialClientDataAux.companyData[
                            'poblacion' +
                                potentialClientDataAux.tipoCliente +
                                'fisica'
                        ],
                    };

                    if (potentialClientDataAux.sameAddressData) {
                        potentialClientDataAux.FiscalAddress =
                            potentialClientDataAux.PhysicalAddress;
                    } else {
                        potentialClientDataAux.FiscalAddress = {
                            StreetType:
                                potentialClientDataAux.companyData[
                                    'tipoVia' +
                                        potentialClientDataAux.tipoCliente +
                                        'fiscal'
                                ],
                            StreetName:
                                potentialClientDataAux.companyData[
                                    'nombreVia' +
                                        potentialClientDataAux.tipoCliente +
                                        'fiscal'
                                ],
                            Number: potentialClientDataAux.companyData[
                                'numero' +
                                    potentialClientDataAux.tipoCliente +
                                    'fiscal'
                            ],
                            Floor: potentialClientDataAux.companyData[
                                'piso' +
                                    potentialClientDataAux.tipoCliente +
                                    'fiscal'
                            ],
                            Door: potentialClientDataAux.companyData[
                                'puerta' +
                                    potentialClientDataAux.tipoCliente +
                                    'fiscal'
                            ],
                            PostalCode:
                                potentialClientDataAux.companyData[
                                    'cp' +
                                        potentialClientDataAux.tipoCliente +
                                        'fiscal'
                                ],
                            PostalCodeExporter:
                                potentialClientDataAux.companyData[
                                    'cp' +
                                        potentialClientDataAux.tipoCliente +
                                        'fiscal'
                                ],
                            Province:
                                potentialClientDataAux.companyData[
                                    'provincia' +
                                        potentialClientDataAux.tipoCliente +
                                        'fiscal'
                                ],
                            Country:
                                potentialClientDataAux.companyData[
                                    'pais' +
                                        potentialClientDataAux.tipoCliente +
                                        'fiscal'
                                ],
                            Town: potentialClientDataAux.companyData[
                                'poblacion' +
                                    potentialClientDataAux.tipoCliente +
                                    'fiscal'
                            ],
                            TownExporter: potentialClientDataAux.companyData[
                                'poblacion' +
                                    potentialClientDataAux.tipoCliente +
                                    'fiscal'
                            ],
                        };
                    }

                    potentialClientDataAux.Mail =
                        potentialClientDataAux.companyData.correo1Input;
                    potentialClientDataAux.OptionalMail =
                        potentialClientDataAux.companyData.optionalEmail1Input;
                    potentialClientDataAux.Phone =
                        potentialClientDataAux.companyData.telefonoInput;
                    potentialClientDataAux.OptionalPhone =
                        potentialClientDataAux.companyData.telefonoOpcionalInput;
                    potentialClientDataAux.Web =
                        potentialClientDataAux.companyData.webInput;
                    break;
                case 2:
                    if (!isUserBeingModified) {
                        potentialClientDataAux.Documents = [];
                    }
                    
                    potentialClientDataAux.userData = [];
                    Array.from(inputs).forEach(element => {

                        if (
                            element &&
                            element.id !== null &&
                            element.id !== undefined &&
                            element.id !== ''
                        ) {
                            potentialClientDataAux.userData[
                                element.id.replace('-' + userType, '')
                            ] = element.value;
                        }
                    });

                    Array.from(selects).forEach(element => {
                        if (
                            element &&
                            element.id !== null &&
                            element.id !== undefined &&
                            element.id !== ''
                        ) {
                            potentialClientDataAux.companyData[
                                element.id.replace('-' + userType, '')
                            ] = element.value;
                        }
                    });

                    potentialClientDataAux.UserNif =
                        potentialClientDataAux.userData.dniUsuario;
                    potentialClientDataAux.UserName =
                        potentialClientDataAux.userData.nombreUsuario;
                    potentialClientDataAux.UserSurname =
                        potentialClientDataAux.userData.pApellidoUsuario;
                    potentialClientDataAux.UserSurname2 =
                        potentialClientDataAux.userData.sApellidoUsuario;
                    potentialClientDataAux.UserMail =
                        potentialClientDataAux.userData.correo2Input;
                    potentialClientDataAux.UserPhone =
                        potentialClientDataAux.userData.telefonoUsuario;

                       
                    if (titularesReales== null) {
                        const array = [];
                        const obj = {};
                        obj.IdTitularReal = 1;
                        obj.NifTitularReal = "NifTitularReal" + obj.IdTitularReal;
                        obj.NombreTitularReal = "";
                        obj.DateBornTitularReal = "";
                        obj.CountryBornTitularReal = "";
                        obj.CountryExpeDocTitularReal = "";
                        obj.NifTitularRealError = "";
                        obj.NombreTitularRealError = "";
                        obj.DateBornTitularRealError = "";
                        obj.CountryBornTitularRealError = "";
                        obj.CountryExpeDocTitularRealError = "";
                        array.push(obj);
                        setTitularesReales(array);
                    }

                    break;
                    case 3:
                        if (!isUserBeingModified) {
                            potentialClientDataAux.Documents = [];
                        }
                        
                        potentialClientDataAux.BeneficialOwner = [];
                        
                        Array.from(inputs).forEach(element => {
                            if (
                                element &&
                                element.id !== null &&
                                element.id !== undefined &&
                                element.id !== ''
                            ) {
                                potentialClientDataAux.BeneficialOwner[
                                    element.id.replace('-' + userType, '')
                                ] = element.value;
                            }
                        });
                        potentialClientDataAux.BeneficialOwner["dateBornTitularReal" + 1] = titularesReales[0].DateBornTitularReal;
                        potentialClientDataAux.BeneficialOwner["countryBornTitularReal" + 1] = titularesReales[0].CountryBornTitularReal;
                        potentialClientDataAux.BeneficialOwner["countryExpeDocTitularReal" + 1] = titularesReales[0].CountryExpeDocTitularReal;
                        
                        const arrayTit = [];   
                        var i = 1;
                        Array.from(titularesReales).forEach(owner => {
                            const objt = {};
                            objt.IdTitularReal = i;
                            objt.Nif = potentialClientDataAux.BeneficialOwner["nifTitularReal" + i];
                            objt.Name = owner.NombreTitularReal;
                            objt.DateBorn = owner.DateBornTitularReal;
                            objt.CountryBorn = owner.CountryBornTitularReal;
                            objt.CountryExpeDoc = owner.CountryExpeDocTitularReal;
                            
                            i++
                            arrayTit.push(objt);
                        });

                        potentialClientDataAux.BeneficialOwners=arrayTit;
                        break;
                default:
                    break;
            }

            //User accept or not ads
            potentialClientDataAux.AcceptAds = userAcceptAd;

            setPotentialClientData(potentialClientDataAux);
            setStep(step + 1);
        }
    }, [
        potentialClientData,
        step,
        sameAddressData,
        inputsNecessaryFilled,
        userType,
        setPotentialClientData,
        userAcceptAd,
        titularesReales
    ]);

    const changePolicyState = useCallback(
        event => {
            if (event && event.currentTarget) {
                if (event.currentTarget.checked) setPrivacyPolicy(true);
                else setPrivacyPolicy(false);
            }
        },
        [setPrivacyPolicy]
    );

    const onChange = useCallback(
        value => {
            if (value != null && value !== undefined && value !== '') {
                setCaptchaValue(value);
            }
        },
        [setCaptchaValue]
    );

    const onChangeOnlyLetters = useCallback(e => {
        const re = /^[A-Za-zñÑ.ºª\s]+$/;

        switch(e.target.id) {
            case "nombreUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = userName;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setUserName(e.target.value);
                }
                e.target.value !== ''
                ? setNombreUserError(
                      null
                  )
                : setNombreUserError(
                      validations.NOMBRE
                  )
                break;
            case "pApellidoUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = userSurname1;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setUserSurname1(e.target.value);
                }

                e.target.value !== ''
                ? setPApellidoError(
                      null
                  )
                : setPApellidoError(
                      validations.APELLIDO
                  )
                break;
            case "sApellidoUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = userSurname2;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setUserSurname2(e.target.value);
                }

                e.target.value !== ''
                ? setSApellidoError(
                      null
                  )
                : setSApellidoError(
                      validations.APELLIDO
                  )
                break;
            default:
            break;
        }

        if(e.target.id.startsWith("nombreTitularReal"))
        {
            var array=[...titularesReales];
            if (array != null) {

                var number = parseInt(e.target.id.substring(17, 18)) - 1;

                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = array[number].NombreTitularReal;
                }
                else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                }
                else {
                    array[number].NombreTitularReal = e.target.value;
                }

                if (e.target.value !== '') {
                    array[number].NombreTitularRealError = "";
                }
                else {
                    array[number].NombreTitularRealError = validations.NOMBRE;
                }
                setTitularesReales(array);
            }
        }


    }, [userName, userSurname1, userSurname2,titularesReales]);

    const onChangePhoneNumber = useCallback( e => {
        const re = /^[0-9\b]+$/;
        switch (e.target.id) {
            case "telefonoInput":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = phone1;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setPhone1(e.target.value);
                }

                e.target.value !== '' ? setTelefonoError(null)
                : setTelefonoError(validations.TELEFONO)
                break;
            case "telefonoOpcionalInput":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = phone2;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setPhone2(e.target.value);
                }
                break;
            case "telefonoUsuario":
                if (!re.test(e.target.value) && e.target.value !== "") {
                    document.getElementById(e.target.id).value = phoneUsuario;
                } else if (e.target.value === "") {
                    document.getElementById(e.target.id).value = "";
                } else {
                    setPhoneUsuario(e.target.value);
                }

                e.target.value !== '' ? setTelefonoUserError(null)
                : setTelefonoUserError(validations.TELEFONO)
                break;
            default:
                break;
        }
    }, [phone1, phone2, phoneUsuario]);


    const onChangeDateBorn = useCallback( e => {
        var val = e?.detail?.args[1];
        if(e.target?.id !== undefined){
            var array=[...titularesReales];
            if (array != null) {

                var number = parseInt(e.target?.id.substring(e.target?.id.length - 1, e.target?.id.length)) - 1;

                if (val!== "") {
                    document.getElementById(e.target?.id).value = val;
                    array[number].DateBornTitularReal = val;
                }
                else if (val === "") {
                    document.getElementById(e.target?.id).value = "";
                }
                

                if (val !== '') {
                    array[number].DateBornTitularRealError = "";
                }
                else {
                    array[number].DateBornTitularRealError = validations.NOMBRE;
                }
                setTitularesReales(array);
            }
        }
    }, [titularesReales]);

const onChangeCountryBorn = useCallback( e => {
    var array=[...titularesReales];
    if (array != null) {

        var number = parseInt(e.target?.id.substring(e.target?.id.length -1, e.target?.id.length)) - 1;

        if (e.target?.value !== "") {
            document.getElementById(e.target?.id).value = e.target?.value;
            array[number].CountryBornTitularReal = e.target?.value;
        }
        else if (e.target?.value === "") {
            document.getElementById(e.target?.id).value = "";
        }
        

        if (e.target?.value !== '') {
            array[number].CountryBornTitularRealError = "";
        }
        else {
            array[number].CountryBornTitularRealError = validations.NOMBRE;
        }
        setTitularesReales(array);
    }

}, [titularesReales]);

const onChangeCountryExpeDoc = useCallback( e => {
    var array=[...titularesReales];
    if (array != null) {

        var number = parseInt(e.target?.id.substring(e.target?.id.length -1, e.target?.id.length)) - 1;

        if (e.target?.value !== "") {
            document.getElementById(e.target?.id).value = e.target?.value;
            array[number].CountryExpeDocTitularReal = e.target?.value;
        }
        else if (e.target?.value === "") {
            document.getElementById(e.target?.id).value = "";
        }
        

        if (e.target?.value !== '') {
            array[number].CountryExpeDocTitularRealError = "";
        }
        else {
            array[number].CountryExpeDocTitularRealError = validations.NOMBRE;
        }
        setTitularesReales(array);
    }

}, [titularesReales]);

    const handlePubliCheckbox = e => {
        //debugger;
        var checkboxes = document.getElementsByName('publi-checkbox');
        checkboxes.forEach(function (checkbox) {
            if (checkbox.id !== e.currentTarget.id) {
                checkbox.checked = false;
            }
        });

        if (e.currentTarget.id === 'yes-publi') {
            setUserAcceptAd('S');
        } else {
            setUserAcceptAd('N');
        }
    };

    const showPrivacyPolicy = e => {
        e.preventDefault();
        setHideModalPolicy(!hideModalPolicy);
    };

    const renderDocumentLabel = useCallback(() => {
        let label = <strong> NIF/NIE </strong>;
        if (userType === 'E')
            label = <strong> Código de Identificación </strong>;
        return label;
    }, [userType]);
     

    const registerStep = useCallback(
        userType => {
            switch (step) {
                case 1:
                    return (
                        <React.Fragment>
                            <div className="c-form-field u-mv-large">
                                <div className="c-form-field__box">
                                    <fieldset>
                                        <div className="o-inline-group">
                                            <span className="o-inline-group__item">
                                                <label className="c-radio">
                                                    <input
                                                        disabled={
                                                            isUserBeingModified
                                                        }
                                                        className="c-radio__input  "
                                                        type="radio"
                                                        name="radio-button"
                                                        defaultChecked={
                                                            userType === 'A'
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() =>
                                                            setUserType('A')
                                                        }
                                                    />
                                                    <span className="c-radio__label">
                                                        {' '}
                                                        Autónomo/Empresa{' '}
                                                    </span>
                                                </label>
                                            </span>
                                            <span className="o-inline-group__item">
                                                <label className="c-radio">
                                                    <input
                                                        disabled={
                                                            isUserBeingModified
                                                        }
                                                        className="c-radio__input  "
                                                        type="radio"
                                                        name="radio-button"
                                                        defaultChecked={
                                                            userType === 'E'
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() =>
                                                            setUserType('E')
                                                        }
                                                    />
                                                    <span className="c-radio__label">
                                                        {' '}
                                                        Exportador{' '}
                                                    </span>
                                                </label>
                                            </span>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div className="c-form-field u-mb">
                                <label
                                    htmlFor="nifNie"
                                    className="c-form-field__label"
                                >
                                    {renderDocumentLabel()}
                                </label>
                                <div className="c-form-field__box">
                                    <div className="c-input  ">
                                        <input
                                            disabled={isUserBeingModified}
                                            className="c-input__input"
                                            id="nifNie"
                                            type="text"
                                            placeholder=""
                                            maxLength={ userType === 'A' ? "9" : "30" }
                                            data-tr-component="Input Field"
                                            data-tr-function="Text Input"
                                            onChange={e => {
                                                userType === 'E'
                                                    ? setNifNieError(null)
                                                    : ValidateSpanishID(
                                                          e.target.value
                                                      )
                                                    ? setNifNieError(null)
                                                    : setNifNieError(
                                                          validations.NIFNIE
                                                      );
                                            }}
                                            defaultValue={
                                                !stringIsNullOrEmpty(
                                                    potentialClientData.CompanyNif
                                                )
                                                    ? potentialClientData.CompanyNif
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <p
                                        className="c-error-message"
                                        id="nifNie-error"
                                    >
                                        {' '}
                                        {nifNieError}{' '}
                                    </p>
                                </div>
                            </div>
                            <div className="c-form-field u-mb-large">
                                <label
                                    htmlFor="nombreEmpresa"
                                    className="c-form-field__label"
                                >
                                    <strong> Nombre de la empresa </strong>
                                </label>
                                <div className="c-form-field__box">
                                    <div className="c-input  ">
                                        <input
                                            className="c-input__input"
                                            id="nombreEmpresa"
                                            type="text"
                                            placeholder=""
                                            data-tr-component="Input Field"
                                            data-tr-function="Text Input"
                                            onChange={e =>
                                                e.target.value !== ''
                                                    ? setNombreEmpresaError(
                                                          null
                                                      )
                                                    : setNombreEmpresaError(
                                                          validations.NOMBREEMPRESA
                                                      )
                                            }
                                            defaultValue={
                                                !stringIsNullOrEmpty(
                                                    potentialClientData.CompanyName
                                                )
                                                    ? potentialClientData.CompanyName
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <p
                                        className="c-error-message"
                                        id="nombreEmpresa-error"
                                    >
                                        {' '}
                                        {nombreEmpresaError}{' '}
                                    </p>
                                </div>
                            </div>
                            <h6 className="u-mv"> DIRECCIÓN PERSONA FÍSICA </h6>
                            <AddressInputs
                                addressType={'fisica'}
                                userType={userType}
                                tipoViaError={tipoViaFisicaError}
                                setTipoViaError={setTipoViaFisicaError}
                                nombreViaError={nombreViaFisicaError}
                                setNombreViaError={setNombreViaFisicaError}
                                cpError={cpFisicaError}
                                setCpError={setCpFisicaError}
                                provinciaError={provinciaFisicaError}
                                setProvinciaError={setProvinciaFisicaError}
                                paisError={paisFisicaError}
                                setPaisError={setPaisFisicaError}
                                direccionError={direccionFisicaError}
                                setDireccionError={setDireccionFisicaError}
                                poblacionError={poblacionFisicaError}
                                setPoblacionError={setPoblacionFisicaError}
                                dataAddress={
                                    potentialClientData?.PhysicalAddress
                                }
                                roadsList={roadsList}
                            />
                            <h6 className="u-mv"> DIRECCIÓN FISCAL </h6>
                            <div className="c-form-field u-mb">
                                <div className="c-form-field__box">
                                    <label className="c-checkbox">
                                        <input
                                            className="c-checkbox__input  "
                                            type="checkbox"
                                            name="checkbox"
                                            onChange={
                                                setSameDataInFiscalAddress
                                            }
                                            checked={sameAddressData}
                                        ></input>
                                        <span className="c-checkbox__label">
                                            {' '}
                                            Usar los mismos datos que en la
                                            Dirección Física{' '}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div id="fiscalAddress">
                                <AddressInputs
                                    addressType={'fiscal'}
                                    userType={userType}
                                    tipoViaError={tipoViaFiscalError}
                                    setTipoViaError={setTipoViaFiscalError}
                                    nombreViaError={nombreViaFiscalError}
                                    setNombreViaError={setNombreViaFiscalError}
                                    cpError={cpFiscalError}
                                    setCpError={setCpFiscalError}
                                    provinciaError={provinciaFiscalError}
                                    setProvinciaError={setProvinciaFiscalError}
                                    poblacionError={poblacionFiscalError}
                                    setPoblacionError={setPoblacionFiscalError}
                                    direccionError={direccionFiscalError}
                                    setDireccionError={setDireccionFiscalError}
                                    dataAddress={
                                        potentialClientData
                                            ? potentialClientData.FiscalAddress
                                            : null
                                    }
                                    roadsList={roadsList}
                                />
                            </div>
                            <h6 className="u-mv"> CONTACTO </h6>
                            <div className="c-form-field">
                                <div className="o-layout">
                                    <div className="o-layout__item u-6/12 u-12/12@xs">
                                        <label
                                            htmlFor="correo1Input"
                                            className="c-form-field__label"
                                        >
                                            <strong> Correo 1 </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    disabled={
                                                        isUserBeingModified
                                                    }
                                                    className="c-input__input"
                                                    id="correo1Input"
                                                    type="text"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e =>
                                                        validateEmail(
                                                            e.target.value
                                                        ) === true
                                                            ? setEmail1Error(
                                                                  null
                                                              )
                                                            : setEmail1Error(
                                                                  validations.CORREO
                                                              )
                                                    }
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.Mail
                                                        )
                                                            ? potentialClientData.Mail
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="correo1Input-error"
                                            >
                                                {' '}
                                                {email1Error}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-6/12 u-12/12@xs">
                                        <label
                                            htmlFor="optionalEmail1Input"
                                            className="c-form-field__label"
                                        >
                                            <strong>Correo 2 (opcional)</strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="optionalEmail1Input"
                                                    type="text"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.OptionalMail
                                                        )
                                                            ? potentialClientData.OptionalMail
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-6/12 u-12/12@xs">
                                        <label
                                            htmlFor="telefonoInput"
                                            className="c-form-field__label"
                                        >
                                            <strong> Teléfono 1 </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="telefonoInput"
                                                    type="numeric"
                                                    pattern="[0-9]*"
                                                    placeholder=""
                                                    maxLength={14}
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e => onChangePhoneNumber(e)}
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.Phone
                                                        )
                                                            ? potentialClientData.Phone
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="telefono-error"
                                            >
                                                {' '}
                                                {telefonoError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-6/12 u-12/12@xs">
                                        <label
                                            htmlFor="telefonoOpcionalInput"
                                            className="c-form-field__label"
                                        >
                                            <strong>
                                                Teléfono 2 (opcional)
                                            </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="telefonoOpcionalInput"
                                                    type="text"
                                                    placeholder=""
                                                    maxLength={14}
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e => onChangePhoneNumber(e)}
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.OptionalPhone
                                                        )
                                                            ? potentialClientData.OptionalPhone
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-12/12">
                                        <label
                                            htmlFor="webInput"
                                            className="c-form-field__label"
                                        >
                                            <strong> Web (opcional) </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="webInput"
                                                    type="text"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.Web
                                                        )
                                                            ? potentialClientData.Web
                                                            : ''
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="o-fieldset u-mt-large">
                                <div className="o-fieldset__row">
                                    <div className="o-button-container  o-button-container--nav">
                                        <button
                                            className="c-btn o-button-container__button"
                                            onClick={() =>
                                                updatePotentialClientData()
                                            }
                                        >
                                            <span className="c-btn__text">
                                                Siguiente
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    );
                case 2:
                    return (
                        <React.Fragment>
                            <div className="c-form-field u-mt-large u-mb">
                                <div className="o-layout">
                                    <div className="o-layout__item u-1/2 u-1/1@xs">
                                        <label
                                            htmlFor="dniUsuario"
                                            className="c-form-field__label"
                                        >
                                            <strong> NIF/NIE </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    disabled={
                                                        isUserBeingModified
                                                    }
                                                    className="c-input__input"
                                                    id="dniUsuario"
                                                    type="text"
                                                    name="dniUsuario"
                                                    placeholder=""
                                                    maxLength="9"
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e =>
                                                        ValidateSpanishIDNoCif(
                                                            e.target.value
                                                        ) === true
                                                            ? setNifNieUserError(
                                                                  null
                                                              )
                                                            : setNifNieUserError(
                                                                  validations.NIFNIE
                                                              )
                                                    }
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.UserNif
                                                        )
                                                            ? potentialClientData.UserNif
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="dniUsuario-error"
                                            >
                                                {' '}
                                                {nifNieUserError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-1/2 u-1/1@xs">
                                        <label
                                            htmlFor="nombreUsuario"
                                            className="c-form-field__label"
                                        >
                                            <strong> Nombre </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="nombreUsuario"
                                                    type="text"
                                                    name="nombreUsuario"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e => onChangeOnlyLetters(e)}
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.UserName
                                                        )
                                                            ? potentialClientData.UserName
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="nombreUsuario-error"
                                            >
                                                {' '}
                                                {nombreUserError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-1/2 u-1/1@xs">
                                        <label
                                            htmlFor="pApellidoUsuario"
                                            className="c-form-field__label"
                                        >
                                            <strong> Primer Apellido </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="pApellidoUsuario"
                                                    type="text"
                                                    name="pApellidoUsuario"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e => onChangeOnlyLetters(e)}
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.UserSurname
                                                        )
                                                            ? potentialClientData.UserSurname
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="pApellidoUsuario-error"
                                            >
                                                {' '}
                                                {pApellidoError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-1/2 u-1/1@xs">
                                        <label
                                            htmlFor="sApellidoUsuario"
                                            className="c-form-field__label"
                                        >
                                            <strong> Segundo Apellido </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="sApellidoUsuario"
                                                    type="text"
                                                    name="sApellidoUsuario"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e => onChangeOnlyLetters(e)}
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.UserSurname2
                                                        )
                                                            ? potentialClientData.UserSurname2
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="sApellidoUsuario-error"
                                            >
                                                {' '}
                                                {sApellidoError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-1/1">
                                        <label
                                            htmlFor="correo2Input"
                                            className="c-form-field__label"
                                        >
                                            <strong> Correo </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    disabled={
                                                        isUserBeingModified
                                                    }
                                                    className="c-input__input"
                                                    id="correo2Input"
                                                    type="text"
                                                    name="correo2Input"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    autoComplete={'off'}
                                                    onChange={e =>
                                                        validateEmail(
                                                            e.target.value
                                                        ) === true
                                                            ? setCorreoUserError(
                                                                  null
                                                              )
                                                            : setCorreoUserError(
                                                                  'Email incorrecto'
                                                              )
                                                    }
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.UserMail
                                                        )
                                                            ? potentialClientData.UserMail
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="correo2Input-error"
                                            >
                                                {' '}
                                                {correoUserError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-1/1">
                                        <label
                                            htmlFor="telefonoUsuario"
                                            className="c-form-field__label"
                                        >
                                            <strong> Nº de teléfono </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="telefonoUsuario"
                                                    type="text"
                                                    name="telefonoUsuario"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    maxLength={14}
                                                    autoComplete={'off'}
                                                    onChange={e => onChangePhoneNumber(e)}
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.UserPhone
                                                        )
                                                            ? potentialClientData.UserPhone
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="telefonoUserInput-error"
                                            >
                                                {' '}
                                                {telefonoUserError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <StepButtons
                                step={step}
                                setStep={setStep}
                                captchaValue={captchaValue}
                                nextStepAction={updatePotentialClientData}
                            />
                        </React.Fragment>
                    );
                case 3:
                        return (<React.Fragment>



                            {/* <div className="c-form-field u-mt-large u-mb">
                                <div className="o-layout">
                                    <div className="o-layout__item u-1/2 u-1/1@xs">
                                        <label
                                            htmlFor="nifTitularReal"
                                            className="c-form-field__label"
                                        >
                                            <strong> NIF Titular Real </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    disabled={
                                                        isUserBeingModified
                                                    }
                                                    className="c-input__input"
                                                    id="nifTitularReal"
                                                    type="text"
                                                    name="nifTitularReal"
                                                    placeholder=""
                                                    maxLength="9"
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e =>
                                                        ValidateSpanishIDNoCif(
                                                            e.target.value
                                                        ) === true
                                                            ? setNifTitularRealError(
                                                                  null
                                                              )
                                                            : setNifTitularRealError(
                                                                  validations.NIFNIE
                                                              )
                                                    }
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.BenefitialOwnerNif
                                                        )
                                                            ? potentialClientData.BenefitialOwnerNif
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="nifTitularReal-error"
                                            >
                                                {' '}
                                                {nifTitularRealError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="o-layout__item u-1/2 u-1/1@xs">
                                        <label
                                            htmlFor="nombreTitularReal"
                                            className="c-form-field__label"
                                        >
                                            <strong> Nombre Titular Real </strong>
                                        </label>
                                        <div className="c-form-field__box">
                                            <div className="c-input  ">
                                                <input
                                                    className="c-input__input"
                                                    id="nombreTitularReal"
                                                    type="text"
                                                    name="nombreTitularReal"
                                                    placeholder=""
                                                    data-tr-component="Input Field"
                                                    data-tr-function="Text Input"
                                                    onChange={e => onChangeOnlyLetters(e)}
                                                    defaultValue={
                                                        !stringIsNullOrEmpty(
                                                            potentialClientData.BenefitialOwnerName
                                                        )
                                                            ? potentialClientData.BenefitialOwnerName
                                                            : ''
                                                    }
                                                />
                                            </div>
                                            <p
                                                className="c-error-message"
                                                id="nombreTitularReal-error"
                                            >
                                                {' '}
                                                {nombreTitularRealError}{' '}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>   */}

<div className="c-form-field u-mt-large u-mb">
                            {ConvertRegister(titularesReales)?.map(function(data,i) {
                                return (
                                    <div key={i} className="o-layout">
                                        <div className="o-layout__item u-1/2 u-1/1@xs">
                                            <label
                                                htmlFor="nifTitularReal"
                                                className="c-form-field__label"
                                            >
                                                <strong> NIF Titular Real </strong>
                                            </label>
                                            <div className="c-form-field__box">
                                                <div className="c-input">
                                                    <input
                                                        data-tr-component="Input Field"
                                                        type="text"
                                                        data-tr-function="Text Input"
                                                        id={"nifTitularReal" + data.IdTitularReal}
                                                        className="c-input__input"
                                                        name={"nifTitularReal" + data.IdTitularReal} 
                                                        onChange={e =>
                                                            ValidateSpanishIDNoCif(
                                                                e.target.value
                                                            ) === true
                                                                ? borrarErrorNifTitularReal(data.IdTitularReal)
                                                                : setErrorNifTitularReal(data.IdTitularReal)
                                                        }
                                                        defaultValue={
                                                            !stringIsNullOrEmpty(potentialClientData.BeneficialOwner)
                                                                ? (!stringIsNullOrEmpty(potentialClientData.BeneficialOwner["nifTitularReal" + data.IdTitularReal])? potentialClientData.BeneficialOwner["nifTitularReal" + data.IdTitularReal]:'')
                                                                : ''
                                                        }                                                        
                                                        />
                                                </div>
                                                <p
                                                className="c-error-message"
                                                id={"nifTitularReal"+data.IdTitularReal+ "-error"}
                                            >
                                                {' '}
                                                {data.NifTitularRealError}{' '}
                                            </p>
                                            </div>

                                        </div>
                                        <div className="o-layout__item u-1/2 u-1/1@xs">
                                            <label
                                                htmlFor={"nombreTitularReal"+ data.IdTitularReal}
                                                className="c-form-field__label"
                                            >
                                                <strong> Nombre Titular Real </strong>
                                            </label>
                                            <div className="c-form-field__box">
                                                <div className="c-input">
                                                    <input
                                                        onChange={e => onChangeOnlyLetters(e)}
                                                        data-tr-component="Input Field"
                                                        type="text"
                                                        data-tr-function="Text Input"
                                                        id={"nombreTitularReal" + data.IdTitularReal}
                                                        className="c-input__input"
                                                        name={"nombreTitularReal" + data.IdTitularReal} 
                                                        defaultValue={
                                                            !stringIsNullOrEmpty(potentialClientData.BeneficialOwner)
                                                                ? (!stringIsNullOrEmpty(potentialClientData.BeneficialOwner["nombreTitularReal" + data.IdTitularReal])? potentialClientData.BeneficialOwner["nombreTitularReal" + data.IdTitularReal]:'')
                                                                : ''
                                                        }  
                                                        
                                                        />
                                                </div>
                                                <p
                                                className="c-error-message"
                                                id={"nombreTitularReal"+data.IdTitularReal+"-error"}
                                            >
                                                {' '}
                                                {data.NombreTitularRealError}{' '}
                                            </p>
                                            </div>
                                        </div>

                                        <br></br>

                                        <div className="o-layout__item u-1/2 u-1/1@xs">
                                            <label
                                                htmlFor={"dateBornTitularReal"+ data.IdTitularReal}
                                                className="c-form-field__label"
                                            >
                                                <strong> Fecha Nacimiento Titular Real </strong>
                                            </label>
                                            <div className="c-form-field__box">
                                                <div className="c-input">
                                                        <DateInput
                                                            label="Fecha Nacimiento Titular Real"
                                                            row
                                                            value={data.DateBornTitularReal}
                                                            onChange={onChangeDateBorn}
                                                            id={"dateBornTitularReal" + data.IdTitularReal}
                                                            name={"dateBornTitularReal" + data.IdTitularReal}
                                                            className="c-input__input"
                                                            defaultValue={
                                                                !stringIsNullOrEmpty(potentialClientData.BeneficialOwner)
                                                                    ? (!stringIsNullOrEmpty(potentialClientData.BeneficialOwner["dateBornTitularReal" + data.IdTitularReal])? potentialClientData.BeneficialOwner["dateBornTitularReal" + data.IdTitularReal]:'')
                                                                    : ''
                                                            } 
                                                        />
                                                
                                                </div>
                                                <p
                                                className="c-error-message"
                                                id={"dateBornTitularReal"+data.IdTitularReal+ "-error"}
                                            >
                                                {' '}
                                                {data.DateBornTitularRealError}{' '}
                                            </p>
                                            </div>

                                        </div>
                                        <div className="o-layout__item u-1/2 u-1/1@xs">
                                           
                                          <label
                                                htmlFor={"countryBornTitularReal"+ data.IdTitularReal}
                                                className="c-form-field__label"
                                            >
                                                <strong> País Nacimiento Titular Real </strong>
                                            </label>
                                            <div className="c-form-field__box">
                                                <div className="c-input">
                                                <SelectInput
                                                        options={selectCountries}
                                                        onChange={onChangeCountryBorn}
                                                        name={"countryBornTitularReal" + data.IdTitularReal} 
                                                        id={"countryBornTitularReal" + data.IdTitularReal}
                                                        row
                                                        value={data.CountryBornTitularReal}
                                                        className="c-input__input"
                                                        defaultValue={
                                                            !stringIsNullOrEmpty(potentialClientData.BeneficialOwner)
                                                                ? (!stringIsNullOrEmpty(potentialClientData.BeneficialOwner["countryBornTitularReal" + data.IdTitularReal])? potentialClientData.BeneficialOwner["countryBornTitularReal" + data.IdTitularReal]:'')
                                                                : ''
                                                        } 
                                                    />
                                                </div>
                                               
                                            </div>
                                        
                                            
                                           <p
                                                className="c-error-message"
                                                id={"countryBornTitularReal"+data.IdTitularReal+"-error"}
                                            >
                                                {' '}
                                                {data.CountryBornTitularRealError}{' '}
                                            </p>
                                        </div>

                                        <div className="o-layout__item u-1/2 u-1/1@xs">
                                           
                                          <label
                                                htmlFor={"countryExpeDocTitularReal"+ data.IdTitularReal}
                                                className="c-form-field__label"
                                            >
                                                <strong> País Emisor Documento Titular Real </strong>
                                            </label>
                                            <div className="c-form-field__box">
                                                <div className="c-input">
                                                <SelectInput
                                                        options={selectCountries}
                                                        onChange={onChangeCountryExpeDoc}
                                                        name={"countryExpeDocTitularReal" + data.IdTitularReal} 
                                                        id={"countryExpeDocTitularReal" + data.IdTitularReal}
                                                        row
                                                        value={data.CountryExpeDocTitularReal}
                                                        className="c-input__input"
                                                        defaultValue={
                                                            !stringIsNullOrEmpty(potentialClientData.BeneficialOwner)
                                                                ? (!stringIsNullOrEmpty(potentialClientData.BeneficialOwner["countryExpeDocTitularReal" + data.IdTitularReal])? potentialClientData.BeneficialOwner["countryExpeDocTitularReal" + data.IdTitularReal]:'')
                                                                : ''
                                                        } 
                                                    />
                                                </div>
                                               
                                            </div>
                                        
                                            
                                           <p
                                                className="c-error-message"
                                                id={"countryExpeDocTitularReal"+data.IdTitularReal+"-error"}
                                            >
                                                {' '}
                                                {data.CountryExpDocTitularRealError}{' '}
                                            </p>
                                        </div>
                                        
                                        <br></br>
                                    </div>
                                    
                            )})}
                        </div>

                        <p
                            className="c-error-message"
                            id={"erroreneral-error"}
                        >
                            {' '}
                            {errorGeneralTitularesReales}{' '}
                        </p>

                            <div className="o-button-container  o-button-container--nav">
                                <button
                                    className="c-btn o-button-container__button"
                                    onClick={e => addTitularReal(e)}
                                >
                                    <span className="c-btn__text">
                                        Añadir Titular +
                                    </span>
                                </button>
                                <button
                                    className="c-btn c-btn--secondary"
                                    onClick={e => eliminarTitularReal(e)}
                                >
                                    <span className="c-btn__text">
                                        Eliminar Titular -
                                    </span>
                                </button>
                            </div>
                            <div className="c-form-field u-mb">
                               
                            </div> 
                       
                        <StepButtons
                                step={step}
                                setStep={setStep}
                                nextStepAction={updatePotentialClientData}
                            />
                        </React.Fragment>);
                case 4:
                    if (isUserBeingModified) {
                        //Mostramos los documentos a modificar solamente
                        var idDoc = documentsCopyUserModification.find(function(doc) {
                            if(doc.DocumentType === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_ID || doc.tipoDoc === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_ID) return true;
                            return false;
                        });
                        var iaeDoc = documentsCopyUserModification.find(function(doc) {
                            if(doc.DocumentType === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_IAE || doc.tipoDoc === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_IAE) return true;
                            return false;
                        });
                        var ivaDoc = documentsCopyUserModification.find(function(doc) {
                            if(doc.DocumentType === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_IVA || doc.tipoDoc === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_IVA) return true;
                            return false;
                        });
                        var cifDoc = documentsCopyUserModification.find(function(doc) {
                            if(doc.DocumentType === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_CIF || doc.tipoDoc === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_CIF) return true;
                            return false;
                        });
                        var TitReDoc = documentsCopyUserModification.find(function(doc) {
                            if(doc.DocumentType === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_TITULAR_REAL || doc.tipoDoc === process.env.REACT_APP_TIPO_DOCUMENTO_FSDOCS_TITULAR_REAL) return true;
                            return false;
                        });
                    }

                    return (
                        <React.Fragment>
                            <p className="u-mt-large">
                                {' '}
                                Debes adjuntar los siguientes documentos:{' '}
                            </p>
                            <ul className="c-ul u-mb-large o-list-bare">
                                { !isUserBeingModified || (isUserBeingModified && idDoc) ?
                                    (
                                        <li id="liIdDoc" className="u-mb-small">
                                            <UploadInline
                                                nombreFicheroASubir="NIF/NIE"
                                                tipoDoc={
                                                    process.env
                                                        .REACT_APP_TIPO_DOCUMENTO_FSDOCS_ID
                                                }
                                                potentialClientData={
                                                    potentialClientData
                                                }
                                                setPotentialClientData={
                                                    setPotentialClientData
                                                }
                                            />
                                        </li>
                                    ) : null}
                                { !isUserBeingModified || (isUserBeingModified && iaeDoc) ?
                                    (
                                        <li id="liIaeDoc" className="u-mb-small">
                                            <UploadInline
                                                nombreFicheroASubir="IAE"
                                                tipoDoc={
                                                    process.env
                                                        .REACT_APP_TIPO_DOCUMENTO_FSDOCS_IAE
                                                }
                                                potentialClientData={
                                                    potentialClientData
                                                }
                                                setPotentialClientData={
                                                    setPotentialClientData
                                                }
                                            />
                                        </li>
                                    ) : null}
                                { !isUserBeingModified || (isUserBeingModified && cifDoc) ?
                                    (
                                        <li id="liCifDoc" className="u-mb-small">
                                            <UploadInline
                                                nombreFicheroASubir="NIF Empresa"
                                                tipoDoc={
                                                    process.env
                                                        .REACT_APP_TIPO_DOCUMENTO_FSDOCS_CIF
                                                }
                                                potentialClientData={
                                                    potentialClientData
                                                }
                                                setPotentialClientData={
                                                    setPotentialClientData
                                                }
                                            />
                                        </li>
                                    ) : null}
                                { !isUserBeingModified || (isUserBeingModified && ivaDoc) ?
                                    (
                                        <li id="liIvaDoc" className="u-mb-small">
                                            <UploadInline
                                                nombreFicheroASubir="IVA intracomunitario (opcional)"
                                                tipoDoc={
                                                    process.env
                                                        .REACT_APP_TIPO_DOCUMENTO_FSDOCS_IVA
                                                }
                                                potentialClientData={
                                                    potentialClientData
                                                }
                                                setPotentialClientData={
                                                    setPotentialClientData
                                                }
                                            />
                                        </li>
                                    ) : null}
                                    { !isUserBeingModified || (isUserBeingModified && TitReDoc) ?
                                    (
                                        <li id="liTitDoc" className="u-mb-small">
                                            <UploadInline
                                                nombreFicheroASubir="Certificado de titularidad real"
                                                tipoDoc={
                                                    process.env
                                                        .REACT_APP_TIPO_DOCUMENTO_FSDOCS_TITULAR_REAL
                                                }
                                                potentialClientData={
                                                    potentialClientData
                                                }
                                                setPotentialClientData={
                                                    setPotentialClientData
                                                }
                                            />
                                        </li>
                                    ) : null}
                            </ul>
                            {/* <Upload /> */}
                            <p className="u-mb">
                                {' '}
                                Formatos aceptados: .jpg, .png, .doc, .pdf{' '}
                            </p>
                            <div className="c-form-field u-mt">
                                <div className="c-table-wrapper c-table--bordered c-table--colored">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th
                                                    className="u-text-center"
                                                    colSpan="2"
                                                >
                                                    INFORMACIÓN BÁSICA SOBRE
                                                    PROTECCIÓN DE DATOS
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td> Responsable </td>
                                                <td>
                                                    {' '}
                                                    VOLKSWAGEN RENTING S.A{' '}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> Finalidad </td>
                                                <td>
                                                    {' '}
                                                    Gestión del registro en la
                                                    aplicación. Otras
                                                    finalidades ver información
                                                    adicional.{' '}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> Legitimación </td>
                                                <td>
                                                    {' '}
                                                    Gestión del registro.
                                                    Interés legítimo y
                                                    consentimiento (conforme a
                                                    lo dispuesto en la
                                                    información adicional){' '}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> Destinatarios </td>
                                                <td>
                                                    {' '}
                                                    No se prevén cesiones salvo
                                                    las legalmente previstas.{' '}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> Derechos </td>
                                                <td>
                                                    {' '}
                                                    Acceder, rectificar y
                                                    suprimir datos, así como
                                                    otros derechos conforme a lo
                                                    dispuesto en la información
                                                    adicional.{' '}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td> Información adicional </td>
                                                <td>
                                                    {' '}
                                                    Ver política de privacidad a
                                                    continuación{' '}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className="c-form-field__box">
                                    <p>
                                        {' '}
                                        Cesión de datos al Grupo Volkswagen para
                                        envío de publicidad:{' '}
                                    </p>
                                    <div className="o-inline-group">
                                        <div className="o-inline-group__item">
                                            <label className="c-checkbox">
                                                <input
                                                    className="c-checkbox__input  "
                                                    id="yes-publi"
                                                    type="checkbox"
                                                    name="publi-checkbox"
                                                    onClick={e =>
                                                        handlePubliCheckbox(e)
                                                    }
                                                />
                                                <span className="c-checkbox__label">
                                                    {' '}
                                                    Sí{' '}
                                                </span>
                                            </label>
                                        </div>
                                        <div className="o-inline-group__item">
                                            <label className="c-checkbox">
                                                <input
                                                    className="c-checkbox__input"
                                                    id="no-publi"
                                                    type="checkbox"
                                                    name="publi-checkbox"
                                                    onClick={e =>
                                                        handlePubliCheckbox(e)
                                                    }
                                                    defaultChecked={true}
                                                />
                                                <span className="c-checkbox__label">
                                                    {' '}
                                                    No{' '}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Modal
                                hideModal={hideModalPolicy}
                                onToggle={showPrivacyPolicy}
                                showModalTitle={false}
                                showModalFooter={false}
                                modalContent={privacyPolicyText}
                            />
                            <div className="c-form-field u-mt-large u-mb">
                                <div className="c-form-field__box">
                                    <label className="c-checkbox">
                                        <input
                                            className="c-checkbox__input  "
                                            id="privacy-policy-input"
                                            type="checkbox"
                                            name="checkbox"
                                            checked={privacyPolicy}
                                            onChange={e => changePolicyState(e)}
                                        />
                                        <span className="c-checkbox__label">
                                            {' '}
                                            He leído la{' '}
                                            <a
                                                href="javascript:void(0)"
                                                onClick={e =>
                                                    showPrivacyPolicy(e)
                                                }
                                            >
                                                {' '}
                                                Política de Privacidad*{' '}
                                            </a>{' '}
                                        </span>
                                    </label>
                                </div>
                            </div>
                            <div className="recaptcha-div">
                                <ReCAPTCHA
                                    onChange={onChange}
                                    sitekey={
                                        process.env.REACT_APP_RECAPTCHA_KEY
                                    }
                                />
                            </div>
                            <StepButtons
                                step={step}
                                setStep={setStep}
                                captchaValue={captchaValue}
                                nextStepAction={
                                    handleDocumentsUploadedValidation
                                }
                            />
                            <p
                                className="c-error-message u-mt"
                                id="upload-documents-error"
                            >
                                {' '}
                                {uploadDocumentsError}{' '}
                            </p>
                        </React.Fragment>
                    );
                case 5:
                    return <SuccessPage />;
                default:
                    break;
            }
        },
        [
            step,
            isUserBeingModified,
            renderDocumentLabel,
            potentialClientData,
            nifNieError,
            nombreEmpresaError,
            tipoViaFisicaError,
            nombreViaFisicaError,
            cpFisicaError,
            provinciaFisicaError,
            paisFisicaError,
            direccionFisicaError,
            poblacionFisicaError,
            roadsList,
            setSameDataInFiscalAddress,
            sameAddressData,
            tipoViaFiscalError,
            nombreViaFiscalError,
            cpFiscalError,
            provinciaFiscalError,
            poblacionFiscalError,
            direccionFiscalError,
            email1Error,
            telefonoError,
            nifNieUserError,
            nombreUserError,
            pApellidoError,
            sApellidoError,
            correoUserError,
            telefonoUserError,
            addTitularReal,
            titularesReales,
            captchaValue,
            updatePotentialClientData,
            setPotentialClientData,
            hideModalPolicy,
            showPrivacyPolicy,
            privacyPolicy,
            onChange,
            handleDocumentsUploadedValidation,
            uploadDocumentsError,
            setUserType,
            changePolicyState,
            onChangeOnlyLetters,
            onChangePhoneNumber,
            onChangeCountryBorn,
            onChangeCountryExpeDoc,
            onChangeDateBorn,
        ]
    );

    return (
        <React.Fragment>
            <div className="o-layout">
                <div className="o-layout__item">
                    <div className="c-form-field">
                        {step < 5 ? (
                            <React.Fragment>
                                <p className="u-mb-large">
                                    {' '}
                                    Por favor, introduce los datos para
                                    completar el registro:{' '}
                                </p>
                                <StepperNav step={step} />
                            </React.Fragment>
                        ) : null}
                    </div>
                    {registerStep(userType)}
                </div>
            </div>
        </React.Fragment>
    );
};

export default NewCompanyForm;
